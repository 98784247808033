import createCache from '@emotion/cache'
import { DEBUG } from '~/config'

const key = 'mfaindex'
const disableWarnings = true
const disableAutoPrefixer = !!DEBUG

// Set up emotion's cache
// see: https://emotion.sh/docs/@emotion/cache
export const emotionCache = createCache({
  key,
  ...(disableAutoPrefixer ? { stylisPlugins: [] } : undefined),
})

if (disableWarnings) emotionCache.compat = true
