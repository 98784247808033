import * as React from 'react'
import { useEffect } from 'react'
import styled from '@emotion/styled'
import { Container } from '@mfaindex/ui'
import { DEBUG } from '~/config'

export type Props = {
  children: React.ReactNode,
}

export const SiteLayout = ({ children }: Props) => {
  useEffect(() => {
    if (!DEBUG) {
      window.sessionStorage.setItem('splashShown', 'yes')
    }
    else {
      window.sessionStorage.removeItem('splashShown')
    }
  }, [])

  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

export const Wrapper = styled(Container)`
  min-height: var(--window-height, 100vh);
`

export default SiteLayout
