import * as React from 'react'
import styled from '@emotion/styled'
import {
  Container,
  Box,
} from '@mfaindex/ui'

export type Props = {
  children: React.ReactNode,
  className?: string,
}

const _ContentPanel = ({ children, className = '' }: Props) => {
  return (
    <ContentPanelContainer className={className}>
      <PanelContent py={2}>
        <PanelContentWrapper>
          {children}
        </PanelContentWrapper>
      </PanelContent>
    </ContentPanelContainer>
  )
}

const ContentPanelContainer = styled(Container)`
  width: 100%;
  height: 100%;
`
const PanelContent = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: scroll;
  direction: rtl;
  ${p => p.theme.mixins.disableScrollbars}
`

const PanelContentWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  min-height: 100%;
  direction: ltr;
`

export const ContentPanel = styled(_ContentPanel)``

export default ContentPanel
