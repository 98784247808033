import * as React from 'react'
import { useState, useRef } from 'react'
import useLayoutEffect from '@react-hook/passive-layout-effect'
import styled from '@emotion/styled'
import { Text, Link, Stack } from '@mfaindex/ui'
import { useViewport } from '~/hooks/ui/useViewport'

type TypeClassProps = {
  title: string,
  className?: string,
  children: React.ReactNode,
}

function TypeClass ({ className, title, children }: TypeClassProps) {
  const ref = useRef<HTMLDivElement>(null)
  const [typeAttrs, setTypeAttrs] = useState<{ [k: string]: null | string }>({
    fontSize: null,
    fontFamily: null,
    fontWeight: null,
  })

  const viewport = useViewport()

  useLayoutEffect(() => {
    if (!ref.current) return
    const style = window.getComputedStyle(ref.current)
    const familyMatch = /"?([^"]+)"?/.exec(style.fontFamily.split(',')[0])
    const { fontSize } = style
    const fontFamily = familyMatch ? familyMatch[1] : null
    const fontWeight = (() => {
      switch (style.fontWeight) {
        case '200':
          return 'xLight'
        case '300':
          return 'Light'
        case '400':
          return ''
        case '700':
          return 'Bold'
        default:
          return style.fontWeight
      }
    })()

    setTypeAttrs({
      fontSize,
      fontFamily,
      fontWeight,
    })
  }, [ref.current, viewport.width])

  return (
    <Stack spacing={1}>
      <label>
        <strong>{title}</strong> - {[typeAttrs.fontFamily, typeAttrs.fontWeight, typeAttrs.fontSize].filter(Boolean).join(', ')}
      </label>
      <div className={className} ref={ref}>
        {children}
      </div>
    </Stack>
  )
}

export const Typography = () => {
  return (
    <Wrapper>
      <Stack spacing={3}>
        <TypeClass title="Body Type" className="body-type">
          <Stack>
            <Text>
              Lorem ipsum dolor sit amet, <Link href="#nop">consectetur adipiscing elit</Link>.
              Suspendisse tincidunt varius porttitor. Proin laoreet ligula id lorem
              iaculis sollicitudin. Nullam pellentesque sem pharetra turpis luctus
              convallis. Etiam vel cursus sapien.  Phasellus et lacinia odio. Sed vel
              dolor nec nisi congue pulvinar. Aenean et neque condimentum, finibus
              metus id, molestie enim. Mauris ex ex, eleifend et condimentum non,
              accumsan convallis dui.
            </Text>
            <Text>
              Consectetur adipiscing elit. Nullam et accumsan
              risus. Donec id ultricies ipsum. Suspendisse potenti. Phasellus vitae dui eu
              ipsum auctor commodo. Vestibulum ante ipsum primis in faucibus orci luctus et
              ultrices posuere cubilia curae; Proin pellentesque dui non justo accumsan,
              congue mollis sem faucibus. Vestibulum arcu purus, auctor et auctor eu,
              vehicula et sapien. Phasellus semper lectus nec mauris imperdiet, eu accumsan
              elit aliquam. Integer vitae lectus nisi. Curabitur ut est in elit porta
              faucibus.
            </Text>
          </Stack>
        </TypeClass>

        <TypeClass title="Heading" className="headingType">
          <Text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et egestas augue.
          </Text>
        </TypeClass>

        <TypeClass title="Small Type" className="smallType">
          <Text>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus et egestas augue.
          </Text>
        </TypeClass>
      </Stack>
    </Wrapper>
  )
}

export const Wrapper = styled.div`
  padding: 0 3rem;
  max-width: 80rem;


  label {
    font-family: monospace;
    font-size: 1.1rem;
    font-style: italic;
    display: inline-block;
    padding: 0.3rem 0;
  }

  .bodyType {
    ${({ theme }) => theme.typography.bodyType}
  }

  .headingType {
    ${({ theme }) => theme.typography.bodyType}
  }

  .smallType {
    ${({ theme }) => theme.typography.smallType}
  }
`

export default Typography
