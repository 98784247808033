import * as React from 'react'
import styled from '@emotion/styled'
import { useEffect, useRef } from 'react'
import queryString from 'query-string'

export type Props = {
  provider: 'vimeo' | 'youtube',
  embed: string,
  width: number,
  height: number,
  autoplay?: boolean,
  className?: string,
}

const VIDEO_OPTIONS = {
  youtube: {
    autoplay: 1,
    modestbranding: 1,
    showinfo: 0,
  },
  vimeo: {
    autoplay: 1,
    badge: 0,
    byline: 0,
    portrait: 0,
    title: 0,
  },
}

export const _VideoEmbed = React.memo(
  ({ provider, embed, width, height, autoplay, className }: Props) => {
    const ref = useRef<HTMLDivElement>(null)

    const initVideoEmbed = () => {
      const el = ref.current as HTMLElement
      if (el) {
        // get video iframe and alter src and set options
        const iframe = el.querySelector('iframe')
        if (iframe) {
          const iframeSrc = iframe.getAttribute('src') as string
          const [url, querystring] = iframeSrc.split('?')
          const query = queryString.parse(querystring)
          const options = Object.assign(
            VIDEO_OPTIONS[provider],
            { autoplay: autoplay === undefined ? 1 : (autoplay ? 1 : 0) },
          )
          if (options) {
            Object.assign(query, options)
          }
          iframe.setAttribute('src', `${url}?${queryString.stringify(query)}`)
        }
      }
    }

    useEffect(initVideoEmbed, [])

    return (
      <div
        ref={ref}
        className={className}
        dangerouslySetInnerHTML={{ __html: embed }}
        style={{
          width: '100%',
          height: 0,
          paddingBottom: `${(height / width) * 100}%`,
        }}
      />
    )
  },
)

export const VideoEmbed = styled(_VideoEmbed)`
  background: ${p => p.theme.colors.black};

  iframe {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export default VideoEmbed
