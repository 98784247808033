import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import { NotFound } from '~/components/NotFound'
import { StudentProfileIndex } from '~/components/students/StudentProfileIndex'
import { IndexProvider } from './IndexProvider'
import { IndexListPage } from './IndexListPage'
import { RandomStudentProfile } from './RandomStudentProfile'

export const Index = () => (
  <IndexProvider>
    <Routes>
      <Route path="" element={<IndexListPage />} />
      <Route path="students/*" element={<StudentProfileIndex />} />
      <Route path="random" element={<RandomStudentProfile />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </IndexProvider>
)

export default Index
