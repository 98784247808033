import * as React from 'react'
import { useCallback, useState, useRef, useEffect } from 'react'
import { mergeRefs } from 'react-merge-refs'
import cn from 'classnames'
import styled from '@emotion/styled'

export interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  src?: string,
  srcSet?: string,
  width?: number,
  height?: number,
}

export const BLANK_SRC = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

/**
 * Image component
 */
export const _Image = React.forwardRef<HTMLImageElement, ImageProps>(
  ({
    src,
    srcSet,
    sizes,
    width,
    height,
    className,
    ...props
  }: ImageProps, ref) => {
    const innerRef = useRef<HTMLImageElement>(null)

    const [isLoaded, setIsLoaded] = useState(false)
    const handleLoad = useCallback((e: any) => {
      setIsLoaded(true)
      if (props.onLoad) props.onLoad(e)
    }, [src])

    useEffect(() => {
      if (innerRef?.current?.complete) setIsLoaded(true)
    }, [innerRef])

    return (
      <img
        ref={mergeRefs([ref, innerRef])}
        src={src || BLANK_SRC}
        srcSet={srcSet}
        sizes={sizes ?? '100vw'}
        alt={props.alt}
        width={width}
        height={height}
        className={cn(className, {
          isLoading: !isLoaded,
        })}
        {...props}
        style={{
          ...props.style,
          // Prevent page shift and incorrect calculations on img dimensions.
          // TODO: This can  be removed once `aspect-ratio: attr(width) / attr(height);` works in all browsers
          aspectRatio: width && height ? `${width} / ${height}` : undefined,
        }}
        onLoad={handleLoad}
      />
    )
  },
)

export const Image = styled(_Image)`
  display: block;
  max-width: 100%;
  height: auto;
  opacity: 1;
  transition: opacity ${p => p.theme.animations.fadeTransition};

  &.isLoading {
    opacity: 0;
  }
`

export default Image
