/* global module */
import { hydrate } from 'react-dom'
import { configureUrqlClient } from '~/utils/urql'
import { emotionCache } from '../utils/emotion'
import ClientApp from './ClientApp'

// urql client
const urqlClient = configureUrqlClient({
  initialState: window.__URQL_STATE__,
})

hydrate(
  <ClientApp urqlClient={urqlClient} emotionCache={emotionCache} />,
  document.getElementById('root'),
)

if (module.hot) {
  module.hot.accept()
}
