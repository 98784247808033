import { useLocation } from 'react-router'
import { Text } from '@mfaindex/ui'
import { ErrorView } from '~/components/ErrorView'

export const NotFound = () => {
  const { pathname } = useLocation()
  return (
    <ErrorView statusCode={404} title="Not Found">
      <Text>Sorry, can&apos;t find {pathname ? <code>{pathname}</code> : 'this page'}</Text>
    </ErrorView>
  )
}

export default NotFound
