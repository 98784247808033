import styled from '@emotion/styled'

export const CodeBlock = styled.code`
  display: block;
  padding: 3rem;
  margin: 1.5rem 0;
  white-space: pre-wrap;
  background-color: ${p => p.theme.colors.gray50};
  border: solid 1px ${p => p.theme.colors.gray300};
`

export default CodeBlock
