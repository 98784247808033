import { useQuery, gql } from 'urql'
import type { ConfigValues } from '~/types'

const QUERY = gql`
  query ConfigValues {
    configValues {
      metatags {
        title
        description
      }
    }
  }
`

export const useConfigValues = () => {
  return useQuery<{ configValues: ConfigValues }>({ query: QUERY })
}
