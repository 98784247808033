import * as React from 'react'
import styled from '@emotion/styled'
import { Container, Heading, Stack } from '@mfaindex/ui'
import { Link } from '~/components/ui/Link'
import { CodeBlock } from '~/components/ui/CodeBlock'
import { HttpStatus } from './HttpStatus'
import { DEBUG, IS_CLIENT, BASE_URL } from '~/config'

export type Props = {
  title: string,
  children?: React.ReactNode,
  className?: string,
  debugMessage?: string,
  statusCode?: number,
}

export const ErrorView = ({ title, children, className = '', debugMessage = '', statusCode }: Props) => (
  <Wrapper className={className}>
    {statusCode ? <HttpStatus code={statusCode} /> : null}

    <Stack textAlign="center" alignItems="center">
      <Heading>{statusCode || ''} {title}</Heading>
      {children ?? 'Oh no! Something went wrong!'}
      <p><Link to={BASE_URL}>Go to the Home Page</Link></p>

      {(DEBUG && IS_CLIENT && debugMessage) ? (
        <CodeBlock>{debugMessage}</CodeBlock>
      ) : null}
    </Stack>
  </Wrapper>
)

const Wrapper = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  min-height: 80vh;

  h1 {
    font-weight: bold;
  }
`

export default ErrorView
