import * as React from 'react'
import { forwardRef } from 'react'
import styled from '@emotion/styled'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import { Link as StyledLink, LinkProps as StyledLinkProps } from '@mfaindex/ui'

export interface LinkProps extends
  StyledLinkProps,
  Pick<RouterLinkProps, 'to' | 'replace' | 'state'> {}

const _Link = forwardRef<any, LinkProps>(
  ({ ...props }: LinkProps, ref) => {
    return (
      <StyledLink
        {...props}
        as={p => <RouterLink {...p} />}
        ref={ref}
      />
    )
  },
)

export const Link = styled(_Link)`
  /* pass */
`

export default Link
