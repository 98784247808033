import * as React from 'react'
import { useMemo, useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import usePrevious from '@react-hook/previous'
import shuffle from 'fast-shuffle'
import { useQuery } from 'urql'
import { IndexContext } from './context'
import { INDEX_QUERY } from '~/gql/index'
import type { IndexView } from './context'
import type { ListStudent, School, Keyword, Text } from '~/types'

export type Props = {
  children: React.ReactNode,
}

type IndexQuery = {
  schools: School[],
  keywords: Keyword[],
  students: ListStudent[],
  aboutText: Text,
  landingText: Text, // TODO: remove if not using
}

/**
 * A React context provider to handle Index state & data
 */
export const IndexProvider = ({ children }: Props) => {
  // Note: We make use of 'useMemo' incrementally for each bit of data that
  // will go into the full context object. That way we only invalidate the
  // values that actually change. This will avoid unnecessary re-rendering of
  // React components that use the context object.

  // The location search params is the source of truth for which search filters
  // are currently applied. Those will be used to filter the full list of students.
  const location = useLocation()
  const previousLocation = usePrevious(location)
  const params = new URLSearchParams(location.search)

  // Get filters from URL search params
  const schoolFilter = useMemo(() => new Set(params.getAll('school')), [location.search])
  const yearFilter = useMemo(() => new Set(params.getAll('year').map(y => parseInt(y, 10))), [location.search])
  const keywordFilter = useMemo(() => new Set(params.getAll('keyword')), [location.search])

  // Combined filters
  const filters = useMemo(() => {
    return {
      school: schoolFilter,
      year: yearFilter,
      keyword: keywordFilter,
      size: schoolFilter.size + yearFilter.size + keywordFilter.size,
    }
  }, [schoolFilter, yearFilter, keywordFilter])

  // Get search query
  const [searchQuery, setSearchQuery] = useState<string | null>(params.get('search'))
  useEffect(() => {
    setSearchQuery(params.get('search'))
  }, [location.search])

  // Get sort direction
  const sortBy = useMemo(() => {
    const sortByParam = params.get('sortBy')
    return (!sortByParam ? null : sortByParam === '-1' ? -1 : 1) as 1 | -1 | null
  }, [location.search])

  // Graphql query
  const [{ data, error, fetching }] = useQuery<IndexQuery>({
    query: INDEX_QUERY,
  })

  if (error) {
    console.error(error.toString())
  }

  // Schools data
  const schools = useMemo(() => (data?.schools ?? []), [data, error, fetching])

  // Students data
  const students = useMemo(() => {
    if (!data?.students) return []
    // Deterministic random sort is based on # of students
    // This means we get a roughly random order that both client and server will agree on.
    const date = new Date()
    const seed = date.getUTCMonth() + date.getUTCDate() + date.getUTCHours() + data.students.length
    return shuffle(seed)(data.students)
  }, [data, error, fetching])

  // Keywords data
  const keywords = useMemo(() => {
    // filter out unused keywords
    const studentKeywords = new Set(students.flatMap(s => s.keywords).map(kw => kw.slug))
    return (data?.keywords ?? []).filter(kw => studentKeywords.has(kw.slug))
  }, [data, error, fetching, students])

  // Years data
  const years = useMemo(() => (
    [...new Set(students.map(s => s.year).sort((a, b) => b - a))]
  ), [students])

  // About text
  const aboutText = useMemo(() => {
    return data?.aboutText?.contentMarkup ?? ''
  }, [data, error, fetching])

  // Landing text
  const landingText = useMemo(() => {
    return data?.landingText?.contentMarkup ?? ''
  }, [data, error, fetching])

  // Which index view should we shouw?
  const [view, setView] = useState<IndexView>('image')

  // Slideshow index
  const [slideshowIndex, setSlideshowIndex] = useState(0)
  useEffect(() => { setSlideshowIndex(0) }, [filters, searchQuery])

  // Create full combined index context
  const indexContext = useMemo(() => {
    return {
      view,
      previousLocation,
      slideshowIndex,
      error: error ?? null,
      isLoading: fetching,
      filters,
      searchQuery,
      sortBy,
      schools,
      keywords,
      students,
      years,
      aboutText,
      landingText,
      setView,
      setSearchQuery,
      setSlideshowIndex,
    }
  }, [
    view,
    previousLocation,
    slideshowIndex,
    fetching,
    error,
    filters,
    searchQuery,
    sortBy,
    schools,
    keywords,
    schools,
    years,
    aboutText,
    landingText,
    setSearchQuery,
    setSlideshowIndex,
  ])

  return (
    <IndexContext.Provider value={indexContext}>
      {children}
    </IndexContext.Provider>
  )
}
