import { createContext } from 'react'
import type { Location } from 'history'
import type { ListStudent, School, Keyword } from '~/types'

export type IndexFilters = {
  school: Set<string>,
  year: Set<number>,
  keyword: Set<string>,
  size: number,
}

export type IndexView = 'list' | 'image' | 'slideshow'

export type IndexContextType = {
  // UI state
  view: IndexView,
  previousLocation: Location<any> | undefined,
  slideshowIndex: number,

  // Data fetching state
  isLoading: boolean,
  error: Error | null,

  // List filters
  filters: IndexFilters,

  // List search
  searchQuery: string | null,

  // List sort
  sortBy: 1 | -1 | null,

  // Data
  schools: School[],
  keywords: Keyword[],
  years: number[],
  students: ListStudent[],
  aboutText: string,
  landingText: string,

  // Methods
  setView: (view: IndexView | ((v: IndexView) => IndexView)) => void,
  setSearchQuery: (term: string | null) => void,
  setSlideshowIndex: (idx: number) => void,
}

/**
 * A React context for index state
 */
export const IndexContext = createContext<IndexContextType>({
  view: 'image',
  previousLocation: undefined,
  slideshowIndex: 0,
  isLoading: false,
  error: null,
  filters: {
    school: new Set(),
    year: new Set(),
    keyword: new Set(),
    size: 0,
  },
  searchQuery: null,
  sortBy: null,
  schools: [],
  keywords: [],
  years: [],
  students: [],
  aboutText: '',
  landingText: '',
  setView: () => undefined,
  setSearchQuery: () => undefined,
  setSlideshowIndex: () => undefined,
})
