import * as React from 'react'
import styled from '@emotion/styled'
import { Box, InferComponentProps } from '@mfaindex/ui'

export type WrapperProps = InferComponentProps<typeof IndexSplashWrapper>

export interface Props extends WrapperProps {}

export const IndexSplashPage = React.forwardRef<any, Props>((props: Props, ref) => {
  return (
    <IndexSplashWrapper
      {...props}
      ref={ref}
    >
      <p>
        MFA Index<br />
        is an online platform started by a consortium of MFA programs to
        showcase the work of their graduates whose studies were impacted by COVID-19.
      </p>
    </IndexSplashWrapper>
  )
})

export const IndexSplashWrapper = styled(Box)`
  position: fixed;
  z-index: ${p => p.theme.zIndices.top};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  p {
    margin: 0;
    padding: 0;
  }

  ${p => p.theme.mediaQueries.xs} {
    padding: 2rem;
    font-size: 6.3vh;

    @media (max-aspect-ratio: 320 / 630) {
      font-size: 6.0vh;
    }

    @media (max-aspect-ratio: 320 / 680) {
      font-size: 5.0vh;
    }
  }

  ${p => p.theme.mediaQueries.sm} {
    font-size: 9vh;

    @media (max-aspect-ratio: 3/2) {
      font-size: 9.0vh;
    }

    @media (orientation: portrait) {
      font-size: 9.0vw;
    }
  }

  ${p => p.theme.mediaQueries.md} {
    z-index: ${p => p.theme.zIndices.content};
    left: 6.2rem;
    width: calc(100% - 12.4rem);
    font-size: 11vmin;

    @media (max-aspect-ratio: 3/2) {
      font-size: 9vh;
    }

    @media (orientation: portrait) {
      font-size: 6.5vh;
    }
  }
`

export default IndexSplashPage
