import * as React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ToggleButton } from '@mfaindex/ui'
import { FilterPanel } from '~/components/ui/FilterPanel'
import { useIndexContext } from '~/hooks/useIndexContext'

export type Props = {
  className?: string,
}

export const YearFilters = ({ className }: Props) => {
  const index = useIndexContext()
  const { filters, years } = index

  // Toggle buttons
  const location = useLocation()
  const navigate = useNavigate()

  const toggle = (year: number) => {
    const params = new URLSearchParams(location.search)
    if (!params.getAll('year').includes(year.toString())) {
      params.append('year', year.toString())
    }
    else {
      const currentFilters = params.getAll('year')
      params.delete('year')
      currentFilters.forEach(y => {
        if (y !== year.toString()) params.append('year', y)
      })
    }

    const querystring = `?${params.toString()}`
    navigate({
      pathname: location.pathname,
      search: querystring === '?' ? undefined : querystring,
    })
  }

  const onClick = (year: number) => (e: React.MouseEvent) => {
    e.preventDefault()
    toggle(year)
  }

  return (
    <FilterPanel className={className}>
      {years.map(year => (
        <ToggleButton
          key={year}
          isChecked={filters.year.has(year)}
          onClick={onClick(year)}
          as={p => <a {...p} href={`${location.pathname}?year=${year}`} />}
        >
          {year}
        </ToggleButton>
      ))}
    </FilterPanel>
  )
}

export default YearFilters
