import { gql } from 'urql'
import { MEDIA_FRAGMENT } from '~/gql/media'

export const GET_STUDENT = gql`
  query Student ($id: ID!) {
    student (id: $id) {
      id
      slug
      firstName
      lastName
      name
      year
      bio
      bioMarkup
      school {
        id
        slug
        name
        shortName
        url
      }
      media {
        ...Media
      }
      keywords {
        id
        name
        slug
      }
    }
  }
${MEDIA_FRAGMENT}
`
