import * as React from 'react'
import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import {
  Container,
  Heading,
  Text,
  Markup,
} from '@mfaindex/ui'
import { MediaView } from '~/components/ui/MediaView'
import { Student } from '~/types'

export type Props = {
  student: Student,
  onShowItem: (index: number) => void,
  activeMediaItem: number,
  isLoaded: boolean,
  className?: string,
}

export const StudentProfileDesktop = ({ student, activeMediaItem, onShowItem, isLoaded, className }: Props) => {
  const [isZoomed, setIsZoomed] = useState(false)
  const onZoomed = (zoom: number) => {
    setIsZoomed(zoom > 1)
  }

  // Disable document scrolling
  useEffect(() => {
    const doc = document.documentElement || document.documentElement
    doc.style.overflow = 'hidden'
    return () => { doc.style.overflow = '' }
  }, [])

  return (
    <Wrapper className={className} isLoaded={isLoaded} isZoomed={isZoomed}>
      <Header as="header" p={[1, 2]}>
        <Heading>{student.name}</Heading>
        <Text mt={1}>
          {student.school.shortName} <br />
          {student.year}
        </Text>
      </Header>

      <Bio as="section" aria-label="Student Bio">
        <Markup p={3}>
          {student.bioMarkup}
        </Markup>
      </Bio>

      <Media as="section" aria-label="Student media">
        {student.media.length > 0 ? (
          <MediaView
            id="student-profile-media"
            items={student.media}
            activeIndex={activeMediaItem}
            onShowItem={onShowItem}
            onZoomed={onZoomed}
            isLoaded={isLoaded}
          />
        ) : (
          <Container
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text>
              Content forthcoming
            </Text>
          </Container>
        )}
      </Media>
    </Wrapper>
  )
}

const Wrapper = styled(Container)<{ isLoaded: boolean, isZoomed: boolean }>`
  height: var(--window-height, 100vh);
  display: grid;
  grid-gap: 1px;
  opacity: ${({ isLoaded }) => isLoaded ? 1 : 0};

  > * {
    box-shadow: 0 0 0 1px ${p => p.theme.colors.fg};
  }

  /* stylelint-disable */
  /* Diable user interactions when image is zoomed in to allow dragging */
  ${({ isZoomed }) => isZoomed && css`
    ${Header},
    ${Bio} {
      user-select: none;
      touch-action: none;
      pointer-events: none;
    }
  `}
  /* stylelint-enable */

  ${p => p.theme.mediaQueries.sm} {
    grid-template-columns: 24rem minmax(0, 1fr);
    grid-template-rows: 24rem minmax(0, 1fr);
    grid-template-areas:
      "header images"
      "bio    images";
  }
`

const Header = styled(Container)`
  grid-area: header;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${Heading} {
    font-weight: bold;
  }

  ${p => p.theme.mediaQueries.smDown} {
    background-color: white;

    p {
      display: none;
    }
  }
`

const Bio = styled(Container)`
  grid-area: bio;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  direction: rtl;

  > ${Markup} {
    overflow: auto;
    scrollbar-width: thin;
    direction: ltr;

    a {
      overflow-wrap: break-word;
      word-break: break-word;
    }
  }

  ${p => p.theme.mediaQueries.smDown} {
    background-color: white;
  }
`

const Media = styled(Container)`
  grid-area: images;
`

export default StudentProfileDesktop
