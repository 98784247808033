import * as React from 'react'
import { useState } from 'react'
import styled from '@emotion/styled'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  Drawer,
  DrawerBody,
  DrawerPanel,
  DrawerContent,
  DrawerPanelButton as PanelButton,
  VisuallyHidden,
  Markup,
} from '@mfaindex/ui'
import { ContentPanel } from '~/components/ui/ContentPanel'
import { useIndexContext } from '~/hooks/useIndexContext'
import { BASE_URL } from '~/config'

type PanelState = 'about' | null

export const StudentProfileLeftPanel = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const index = useIndexContext()

  const handleBackClick = (e: React.MouseEvent) => {
    if (index.previousLocation?.pathname === BASE_URL) {
      e.stopPropagation()
      e.preventDefault()
      navigate(-1)
    }
  }

  // Panel state
  const [activePanel, setActivePanel] = useState<PanelState>(null)
  const isOpen = !!activePanel
  const isActive = (panel: PanelState) => panel === activePanel

  // Toggle active panel
  const toggle = (panel: PanelState) => () => {
    setActivePanel(active => active === panel ? null : panel)
  }

  // Close the panel
  const close = () => {
    setActivePanel(null)
  }

  return (
    <Drawer orientation="left" isOpen={isOpen} onClose={close}>
      <DrawerContent>
        <ProfilePanel bg={isOpen ? undefined : 'transparent'}>
          <PanelButton
            onClick={toggle('about')}
            isActive={isActive('about')}
          >
            <strong>MFA Index</strong>
          </PanelButton>
          <PanelButton
            onClick={handleBackClick}
            as={p => <Link {...p} to={{ pathname: BASE_URL, search: location.search }} />}
          >
            Back to Index
          </PanelButton>
        </ProfilePanel>
        <DrawerBody>
          <VisuallyHidden isHidden={!isActive('about')}>
            <ContentPanel>
              <VisuallyHidden><h2>About MFA Index</h2></VisuallyHidden>
              <Markup>{index.aboutText}</Markup>
            </ContentPanel>
          </VisuallyHidden>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

const ProfilePanel = styled(DrawerPanel)`
  border-right: 0;

  ${p => p.theme.mediaQueries.xs} {
    display: none;
  }
`

export default StudentProfileLeftPanel
