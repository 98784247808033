import { gql } from 'urql'
import { MEDIA_FRAGMENT } from '~/gql/media'

export const INDEX_QUERY = gql`
  query Index  {
    schools {
      id
      slug
      name
      shortName
      url
    }

    keywords (approved: true) {
      id
      slug
      name
    }

    students (published: true) {
      id
      slug
      firstName
      lastName
      name
      year
      bio
      school {
        slug
      }
      media (limit: 1) {
        ...Media
      }
      keywords {
        slug
      }
    }

    aboutText: text (slug: "mfa-index") {
      id
      slug
      contentMarkup
    }

    landingText: text (slug: "landing-page") {
      id
      slug
      contentMarkup
    }
  }
${MEDIA_FRAGMENT}
`
