import * as React from 'react'
import styled from '@emotion/styled'
import {
  Container,
  Box,
  Stack,
} from '@mfaindex/ui'

export type Props = {
  children: React.ReactNode,
  className?: string,
}

export const FilterPanel = ({ children, className = '' }: Props) => {
  return (
    <FilterPanelContainer className={className}>
      <PanelContent py={2}>
        <PanelStack>
          {children}
        </PanelStack>
      </PanelContent>
    </FilterPanelContainer>
  )
}

const FilterPanelContainer = styled(Container)`
  width: 100%;
  height: 100%;
`
const PanelContent = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: scroll;
  direction: rtl;
  ${p => p.theme.mixins.disableScrollbars}
`

const PanelStack = styled(Stack)`
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  min-height: 100%;
  direction: ltr;
`

export default FilterPanel
