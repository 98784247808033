import * as React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import {
  ButtonPanel,
  DrawerPanelButton as PanelButton,
} from '@mfaindex/ui'
import { useNextPrevStudents } from '~/hooks/useNextPrevStudents'
import { truncate } from '~/utils/strings'

export const StudentProfileRightPanel = () => {
  const [prev, next] = useNextPrevStudents()
  return (
    <Panel orientation="right">
      {!!prev && (
        <PanelButton as={p => <Link {...p} to={prev.url} />}>
          &lt; {truncate(prev.student.name, 36)}
        </PanelButton>
      )}
      {!!next && (
        <PanelButton as={p => <Link {...p} to={next.url} />}>
          {truncate(next.student.name, 36)} &gt;
        </PanelButton>
      )}
    </Panel>
  )
}

const Panel = styled(ButtonPanel)`
  position: fixed;
  z-index: ${p => p.theme.zIndices.drawer};
  top: -1px;
  right: -1px;
  height: calc(var(--window-height, 100vh) + 2px);
  background-color: transparent;

  ${p => p.theme.mediaQueries.xs} {
    display: none;
  }
`

export default StudentProfileRightPanel
