import '~/styles/fonts/stylesheet.css'
import { css, Global } from '@emotion/react'
import normalize from 'emotion-normalize'
import { theme } from '~/styles/theme'

export const globalStyles = css`
  /* normalize styles */
  ${normalize}

  *,
  *::after,
  *::before {
    box-sizing: inherit;
  }

  :root {
    box-sizing: border-box;

    /*
     * Base font-size
     * For easy calculations, 1rem == 10px
     */
    font-size: 10px;

    /*
    // ${theme.mediaQueries.screen2k} {
    //   font-size: calc(10px * 1.33);
    // }

    // ${theme.mediaQueries.screen4k} {
    //   font-size: calc(10px * 2);
    // }
    */
  }

  body {
    position: relative;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    color: ${theme.colors.text};
    ${theme.typography.bodyType}
  }

  #root {
    position: relative;
  }

  small {
    ${theme.typography.smallType}
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${theme.typography.bodyType}
  }

  ol,
  ul {
    padding-left: 0;
  }

  li {
    list-style-position: inside;
  }

  p {
    margin: 0;
    padding: 0;
  }

  a {
    color: ${theme.colors.text};
  }

  p a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  address {
    font-style: normal;
  }

  pre,
  code {
    margin: 0;
    font-family: monospace;
    font-size: 1.4rem;
  }
`

export const GlobalStyle = () => (
  <Global styles={globalStyles} />
)

export default GlobalStyle
