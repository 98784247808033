import { theme as baseTheme } from '@mfaindex/ui'

const colors = baseTheme.colors

// Altered colors
const fg = colors.black

export const theme = {
  ...baseTheme,
  colors: {
    ...colors,
    fg,
    primary: fg,
    disabled: fg,
    text: fg,
    focus: fg,
    link: fg,
    linkHover: fg,
  },
}

export type Theme = typeof theme

export default theme
