import * as React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useIndexContext } from '~/hooks/useIndexContext'
import { useFilteredStudents } from '~/hooks/useFilteredStudents'
import { getStudentProfileLink } from '~/utils/students'

function getRandom (min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min)
}

export const RandomStudentProfile = () => {
  const { isLoading } = useIndexContext()
  const students = useFilteredStudents()
  const location = useLocation()

  if (isLoading) return null
  if (!students.length) return null

  const randomStudent = students[getRandom(0, students.length)]
  const { slug, id } = randomStudent

  return (
    <Navigate to={getStudentProfileLink(slug, id, location.search)} replace />
  )
}

export default RandomStudentProfile
