import * as React from 'react'
import { useState } from 'react'
import useChange from '@react-hook/change'
import { Link } from 'react-router-dom'
import {
  Drawer,
  DrawerBody,
  DrawerPanel,
  DrawerContent,
  DrawerPanelButton as PanelButton,
  VisuallyHidden,
  Text,
  Markup,
} from '@mfaindex/ui'
import { SchoolFilters } from './SchoolFilters'
import { YearFilters } from './YearFilters'
import { KeywordFilters } from './KeywordFilters'
import { ContentPanel } from '~/components/ui/ContentPanel'
import { useIndexContext } from '~/hooks/useIndexContext'
import { BASE_URL } from '~/config'

type PanelState = 'about' | 'school' | 'year' | 'keyword' | null

export const IndexDrawerLeft = () => {
  const index = useIndexContext()

  // Panel state
  const [activePanel, setActivePanel] = useState<PanelState>(null)
  const isOpen = !!activePanel
  const isActive = (panel: PanelState) => panel === activePanel

  // Toggle active panel
  const toggle = (panel: PanelState) => () => {
    setActivePanel(active => active === panel ? null : panel)
  }

  // Close the panel
  const close = () => {
    setActivePanel(null)
  }

  // Close drawer on filter change
  useChange(index.filters, close)

  // Detect if any filters are enabled
  const filtersEnabled = !!index.searchQuery || index.filters.size > 0

  return (
    <Drawer orientation="left" isOpen={isOpen} onClose={close}>
      <DrawerContent>
        <DrawerPanel bg={!isOpen && index.view === 'slideshow' ? 'transparent' : undefined}>
          <PanelButton
            onClick={toggle('about')}
            isActive={isActive('about')}
          >
            <strong>MFA Index</strong>
          </PanelButton>
          <PanelButton
            onClick={toggle('school')}
            isActive={isActive('school')}
            colorVariant={!isActive('school') && index.filters.school.size > 0 ? 'inverted' : undefined}
          >
            School
          </PanelButton>
          <PanelButton
            onClick={toggle('year')}
            isActive={isActive('year')}
            colorVariant={!isActive('year') && index.filters.year.size > 0 ? 'inverted' : undefined}
          >
            Year
          </PanelButton>
          <PanelButton
            onClick={toggle('keyword')}
            isActive={isActive('keyword')}
            colorVariant={!isActive('keyword') && index.filters.keyword.size > 0 ? 'inverted' : undefined}
          >
            Keyword
          </PanelButton>
          {(!isOpen && filtersEnabled) && (
            <PanelButton
              square
              as={p => <Link {...p} to={BASE_URL} />}
              onClick={close}
              title="Clear filters"
              aria-label="Clear list filters"
            >
              <Text fontSize="1.6rem">x</Text>
            </PanelButton>
          )}
          {isOpen && (
            <PanelButton
              square
              onClick={close}
              title="Close the drawer"
              aria-label="Close the drawer"
            >
              <Text fontSize="1.6rem">→</Text>
            </PanelButton>
          )}
        </DrawerPanel>
        <DrawerBody py={0}>
          <VisuallyHidden isHidden={!isActive('about')}>
            <ContentPanel>
              <VisuallyHidden><h2>About MFA Index</h2></VisuallyHidden>
              <Markup>{index.aboutText}</Markup>
            </ContentPanel>
          </VisuallyHidden>
          <VisuallyHidden isHidden={!isActive('school')}>
            <SchoolFilters />
          </VisuallyHidden>
          <VisuallyHidden isHidden={!isActive('year')}>
            <YearFilters />
          </VisuallyHidden>
          <VisuallyHidden isHidden={!isActive('keyword')}>
            <KeywordFilters />
          </VisuallyHidden>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default IndexDrawerLeft
