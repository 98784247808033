import * as React from 'react'
import { ErrorView } from '~/components/ErrorView'
import { DEBUG } from '~/config'

export type Props = {
  children: React.ReactNode,
}

type State = {
  error: Error | null,
  info: React.ErrorInfo | null,
  prevProps?: Props | null,
}

export class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    error: null,
    info: null,
  }

  componentDidCatch (error: Error | null, info: React.ErrorInfo) {
    this.setState({
      error: error || new Error('missing error'),
      info,
    })
  }

  static getDerivedStateFromProps (props: Props, state: State) {
    const propsChanged = props === state.prevProps
    const newState = propsChanged
      ? { ...state }
      : { ...state, error: null, info: null }
    return {
      ...newState,
      prevProps: props,
    }
  }

  render () {
    const { error } = this.state
    if (!error) {
      return this.props.children
    }

    const { info } = this.state
    const componentStack = (info && info.componentStack) || ''
    const msg = `${error.name}: ${error.message}\n${componentStack}`
    return (
      <ErrorView
        title="Error"
        debugMessage={DEBUG ? msg : ''}
      />
    )
  }
}

export default ErrorBoundary
