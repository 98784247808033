import * as React from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ToggleButton } from '@mfaindex/ui'
import { FilterPanel } from '~/components/ui/FilterPanel'
import { useIndexContext } from '~/hooks/useIndexContext'

export type Props = {
  className?: string,
}

export const KeywordFilters = ({ className }: Props) => {
  const index = useIndexContext()
  const { filters, keywords } = index

  // Toggle buttons
  const location = useLocation()
  const navigate = useNavigate()

  const toggle = (slug: string) => {
    const params = new URLSearchParams(location.search)
    if (!params.getAll('keyword').includes(slug)) {
      params.append('keyword', slug)
    }
    else {
      const currentFilters = params.getAll('keyword')
      params.delete('keyword')
      currentFilters.forEach(s => {
        if (s !== slug) params.append('keyword', s)
      })
    }

    const querystring = `?${params.toString()}`
    navigate({
      pathname: location.pathname,
      search: querystring === '?' ? undefined : querystring,
    })
  }

  const onClick = (slug: string) => (e: React.MouseEvent) => {
    e.preventDefault()
    toggle(slug)
  }

  return (
    <FilterPanel className={className}>
      {keywords.map(keyword => (
        <ToggleButton
          key={keyword.id}
          isChecked={filters.keyword.has(keyword.slug)}
          onClick={onClick(keyword.slug)}
          as={p => <a {...p} href={`${location.pathname}?keyword=${keyword.slug}`} />}
        >
          {keyword.name}
        </ToggleButton>
      ))}
    </FilterPanel>
  )
}

export default KeywordFilters
