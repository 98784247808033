import { createContext, useContext, ReactNode } from 'react'

/**
 * This is a React context provider to enable ssr rendered components to set
 * http status code and redirect locations with react-router.
 */

// redirect status codes
export type RedirectStatusCode =
  | 301 // Permanent
  | 302 // 302 found
  | 303 // See other
  | 307 // Temporary
  | 308 // Permanent

// Context data payload
export type HttpContextData =
  | { status: number, location?: undefined }
  | { status: RedirectStatusCode, location: string }

type HttpContextValue = {
  data: Readonly<HttpContextData>,
  update: (ctx: HttpContextData) => void,
}

/**
 * React context
 */
export const HttpContext = createContext<HttpContextValue>({
  data: { status: 200 },
  update: (ctx: HttpContextData) => undefined,
})

/**
 * Context provider component
 */
type Props = {
  context: HttpContextData,
  children?: ReactNode,
}

export const HttpContextProvider = ({ context, children }: Props) => {
  const update = (ctx: HttpContextData) => {
    if (ctx.status) context.status = ctx.status
    if (ctx.location) context.location = ctx.location
  }
  return (
    <HttpContext.Provider value={{ data: context, update }}>
      <>{children}</>
    </HttpContext.Provider>
  )
}

/**
 * Context hook:
 *
 * const httpContext = useHttpContext()
 * httpContext.update({ status: 404 })
 */
export const useHttpContext = () => {
  return useContext(HttpContext)
}
