import * as React from 'react'
import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router'
import { gql, useMutation } from 'urql'
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  ErrorMessage,
  Stack,
  Text,
  Center,
} from '@mfaindex/ui'

type FormData = {
  name: string,
  email: string,
  program: string,
  role: string,
  message: string,
}

const EMAIL_RE = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

const MUTATION = gql`
mutation ContactForm(
  $name:String!,
  $email:String!,
  $message:String!,
  $program: String!,
  $role: String!,
) {
  submitContactForm(
    name: $name,
    email: $email,
    message: $message,
    program: $program,
    role: $role,
  )
}
`

export const ContactForm = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [showForm, setShowForm] = useState(false)

  useEffect(() => {
    setShowForm(location.hash === '#contact-us')
  }, [location.hash])

  const { register, handleSubmit, formState: { errors }, reset } = useForm<FormData>()

  const [{ error: serverError, fetching }, submitContactForm] = useMutation(MUTATION)
  const [isSubmitted, setSubmitted] = useState(false)

  const onSubmit = async (variables: FormData) => {
    const { data } = await submitContactForm(variables)
    if (data.submitContactForm) {
      setSubmitted(true)
      reset()
    }
  }

  const onClose = () => {
    reset()
    setSubmitted(false)
    navigate({
      hash: '',
      pathname: location.pathname,
    })
  }

  const isFinished = isSubmitted && !serverError

  return showForm ? (
    <Modal onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <ModalContent>
          <ModalBody width="calc(100vw - 4rem)" maxWidth="70rem" py={2}>
            <Stack alignItems="stretch">

              {isFinished ? (
                <Center>
                  <Text><strong>Thank you for your inquiry</strong></Text>
                  <Text>We&apos;ll be in touch shortly.</Text>
                  <Button sizing="sm" onClick={onClose}>Close</Button>
                </Center>
              ) : (
                <>
                  <FormControl name="name">
                    <FormLabel>Name</FormLabel>
                    <Input
                      {...register('name', {
                        required: 'This field is required',
                        maxLength: { value: 255, message: 'Limit to 255 characters' },
                      })}
                    />
                    {errors.name && (
                      <ErrorMessage>{errors.name.message}</ErrorMessage>
                    )}
                  </FormControl>

                  <FormControl name="email">
                    <FormLabel>Email</FormLabel>
                    <Input
                      type="email"
                      {...register('email', {
                        required: 'This field is required',
                        maxLength: { value: 255, message: 'Limit to 255 characters' },
                        pattern: {
                          value: EMAIL_RE,
                          message: 'Must be a valid email',
                        },
                      })}
                    />
                    {errors.email && (
                      <ErrorMessage>{errors.email.message}</ErrorMessage>
                    )}
                  </FormControl>

                  <FormControl name="program">
                    <FormLabel>Program - the name of your university or program</FormLabel>
                    <Input
                      {...register('program', {
                        required: 'This field is required',
                        maxLength: { value: 255, message: 'Limit to 255 characters' },
                      })}
                    />
                    {errors.program && (
                      <ErrorMessage>{errors.program.message}</ErrorMessage>
                    )}
                  </FormControl>

                  <FormControl name="role">
                    <FormLabel>Role - student, admin, profressor, etc.</FormLabel>
                    <Input
                      {...register('role', {
                        required: 'This field is required',
                        maxLength: { value: 255, message: 'Limit to 255 characters' },
                      })}
                    />
                    {errors.role && (
                      <ErrorMessage>{errors.role.message}</ErrorMessage>
                    )}
                  </FormControl>

                  <FormControl name="message">
                    <FormLabel>Message</FormLabel>
                    <Textarea
                      rows={4}
                      {...register('message', {
                        required: 'This field is required',
                        maxLength: { value: 255, message: 'Limit to 255 characters' },
                      })}
                    />
                    {errors.message && (
                      <ErrorMessage>{errors.message.message}</ErrorMessage>
                    )}
                  </FormControl>

                  {serverError && (
                    <ErrorMessage>
                      Sorry! The server experienced an error.
                      Please try submitting the form again.
                    </ErrorMessage>
                  )}
                </>
              )}
            </Stack>
          </ModalBody>
          {!isFinished && (
            <ModalFooter pt={0}>
              <Button width={['auto', '20rem']} onClick={onClose}>Cancel</Button>
              <Button type="submit" width={['auto', '20rem']} disabled={fetching}>Submit</Button>
            </ModalFooter>
          )}
        </ModalContent>
      </form>
    </Modal>
  ) : null
}

export default ContactForm
