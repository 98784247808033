import * as React from 'react'
import styled from '@emotion/styled'
import { Link, useLocation } from 'react-router-dom'
import {
  Container,
  GridLayout,
  Button,
} from '@mfaindex/ui'
import { getStudentProfileLink } from '~/utils/students'
import type { ListStudent } from '~/types'

export type Props = {
  students: ListStudent[],
}

export const SimpleIndexList = ({ students }: Props) => {
  const location = useLocation()
  const getLink = (slug: string, id: string) => (
    getStudentProfileLink(slug, id, location.search, location.pathname)
  )
  return (
    <Container p={[1, '2.5rem']}>
      <Grid>
        {students.map(student => (
          <Button
            key={student.id}
            as={p => <Link {...p} to={getLink(student.slug, student.id)} />}
          >
            <span>{student.name}</span>
          </Button>
        ))}
      </Grid>
    </Container>
  )
}

const Grid = styled(GridLayout)`
  ${Button} {
    position: relative;
    max-width: 100%;

    > span {
      display: block;
      max-width: 100%;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }
`

export default SimpleIndexList
