import { gql } from 'urql'

/**
 * Image fragment
 */
export const IMAGE_FRAGMENT = gql`
  fragment Image on Image {
    id
    type
    caption
    captionMarkup
    props {
      id
      url
      contentType
      altText
      sizes
    }
  }
`

/**
 * Video fragment
 */
export const VIDEO_FRAGMENT = gql`
  fragment Video on Video {
    id
    type
    caption
    captionMarkup
    props {
      id
      url
      provider
      title
      embed
      width
      height
      thumbnails
    }
  }
`

export const MEDIA_FRAGMENT = gql`
  fragment Media on Media {
    ...on MediaItem {
      id
      type
      caption
      captionMarkup
    }
    ...on Image {
      props {
        id
        url
        contentType
        altText
        sizes
      }
    }
    ...on Video {
      props {
        id
        url
        provider
        title
        embed
        width
        height
        thumbnails
      }
    }
  }
`
