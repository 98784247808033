import * as React from 'react'
import { Routes, Route } from 'react-router-dom'
import { NotFound } from '~/components/NotFound'
import { StudentProfilePage } from './StudentProfilePage'

export const StudentProfileIndex = () => (
  <Routes>
    <Route path=":slug/:id" element={<StudentProfilePage />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default StudentProfileIndex
