import * as React from 'react'
import { Link } from 'react-router-dom'
import { Stack, Text, Button } from '@mfaindex/ui'
import { SimpleIndexList } from './SimpleIndexList'
import { ImageIndexList } from './ImageIndexList'
import { SlideshowIndexList } from './SlideshowIndexList'
import { BASE_URL } from '~/config'
import type { ListStudent } from '~/types'
import type { IndexView } from './context'

export type Props = {
  students: ListStudent[],
  view: IndexView,
}

export const IndexList = ({ students, view }: Props) => {
  if (!students.length) {
    return (
      <Stack p={3} height="100vh" justifyContent="center" alignItems="center">
        <Text>
          No students matched your search criteria.
        </Text>

        <Button
          as={p => <Link {...p} to={BASE_URL} />}
          aria-label="Clear list filters"
          width={['10rem', '15rem']}
        >
          Clear
        </Button>
      </Stack>
    )
  }
  switch (view) {
    case 'list':
      return <SimpleIndexList students={students} />
    case 'image':
      return <ImageIndexList students={students} />
    case 'slideshow':
      return <SlideshowIndexList students={students} />
    default:
      return null
  }
}

export default IndexList
