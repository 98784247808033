import * as React from 'react'
import { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { useLocation } from 'react-router-dom'
import {
  Container,
  Heading,
  Text,
  Markup,
  PanelButton,
} from '@mfaindex/ui'
import { Link } from '~/components/ui/Link'
import { MediaView } from '~/components/ui/MediaView'
import { useNextPrevStudents } from '~/hooks/useNextPrevStudents'
import { BASE_URL } from '~/config'
import type { Student } from '~/types'

export type Props = {
  student: Student,
  onShowItem: (index: number) => void,
  activeMediaItem: number,
  isLoaded: boolean,
  className?: string,
}

export const StudentProfileMobile = ({ student, activeMediaItem, onShowItem, isLoaded, className }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  const [tab, selectTab] = useState<'bio' | 'caption'>('caption')
  const selectBio = () => selectTab('bio')
  const selectCaption = () => selectTab('caption')

  const caption = student.media[activeMediaItem]?.caption.trim() || ''

  // Default tab is caption but only if there is one
  useEffect(() => {
    if (caption) selectCaption()
    else selectBio()
  }, [caption])

  // Disable document scrolling
  useEffect(() => {
    const doc = document.documentElement || document.documentElement
    doc.style.overflow = 'hidden'
    return () => { doc.style.overflow = '' }
  }, [])

  const location = useLocation()

  const [prev, next] = useNextPrevStudents()

  return (
    <Wrapper className={className} isLoaded={isLoaded}>
      <TopDrawer isOpen={isOpen} hasCaption={!!caption}>
        <Logo as={p => <Link {...p} to={{ pathname: BASE_URL, search: location.search }} />}>
          <strong>MFA Index</strong>
        </Logo>

        {!!prev && <PrevButton to={prev.url}><span>{prev.student.name}</span></PrevButton>}
        {!!next && <NextButton to={next.url}><span>{next.student.name}</span></NextButton> }

        <PanelHeading>{student.name}</PanelHeading>

        <AboutButton onClick={selectBio} isActive={tab === 'bio'}>About</AboutButton>
        {!!caption && <CaptionButton onClick={selectCaption} isActive={tab === 'caption'}>Image Caption</CaptionButton>}
        <BackButton onClick={close}>Back to image</BackButton>

        {tab === 'bio' && (
          <Bio>
            <Markup>{student.bioMarkup}</Markup>
          </Bio>
        )}
        {tab === 'caption' && (
          <Caption>
            <Markup>{caption}</Markup>
          </Caption>
        )}
      </TopDrawer>

      {isOpen && <Backdrop onClick={close} onTouchStart={close} />}

      {!isOpen && (
        <Header as="header">
          <button onClick={open}><Heading>{student.name}</Heading></button>
        </Header>
      )}

      <Media as="section" aria-label="Student media">
        {student.media.length > 0 ? (
          <MediaView
            id="student-profile-media"
            items={student.media}
            activeIndex={activeMediaItem}
            onShowItem={onShowItem}
            isLoaded={isLoaded}
          />
        ) : (
          <Container
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Text>
              Content forthcoming
            </Text>
          </Container>
        )}
      </Media>
    </Wrapper>
  )
}

const Wrapper = styled(Container)<{ isLoaded: boolean }>`
  padding: 4rem 2.3rem;
  height: var(--window-height, 100vh);
  opacity: ${({ isLoaded }) => isLoaded ? 1 : 0};

  ${p => p.theme.mediaQueries.sm} {
    padding: 0;
  }
`

const Logo = styled(PanelButton)`
  grid-area: l;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
`

const PanelHeading = styled(Heading)`
  grid-area: a;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const PanelLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  padding: 0 1.8rem;
  text-decoration: none !important;
  white-space: nowrap;

  > span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const PrevButton = styled(PanelLink)`
  grid-area: p;

  &::before {
    content: '< ';
    display: inline-block;
    margin-right: 1ex;
  }
`

const NextButton = styled(PanelLink)`
  grid-area: n;

  &::after {
    content: ' >';
    display: inline-block;
    margin-left: 1ex;
  }
`

const TabButton = styled(PanelButton)`
  border: 0;

  ${p => p.isActive && css`
    &&,
    &&:hover {
      color: ${p.theme.colors.bg} !important;
      background-color: ${p.theme.colors.fg} !important;
    }
  `}
`

const AboutButton = styled(TabButton)`
  grid-area: i;
`

const CaptionButton = styled(TabButton)`
  grid-area: c;
`

const BackButton = styled(PanelButton)`
  grid-area: b;
`

const Header = styled.div`
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: 4rem;
  width: 100%;
  background-color: transparent;
  border-bottom: solid 1px;

  button {
    appearance: none;
    background: transparent;
    box-shadow: none;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  ${p => p.theme.mediaQueries.sm} {
    height: 6.2rem;
  }
`

const DrawerText = styled.div`
  position: relative;
  height: 100%;
  text-align: center;

  > ${Markup} {
    width: 100%;
    height: 100%;
    padding: 2.3rem;
    overflow: auto;
    scrollbar-width: thin;
  }
`

const Bio = styled(DrawerText)`
  grid-area: z;
`

const Caption = styled(DrawerText)`
  grid-area: z;
`

const TopDrawer = styled(Container)<{ isOpen: boolean, hasCaption: boolean }>`
  position: fixed;
  z-index: ${p => p.theme.zIndices.drawer};
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  background-color: ${p => p.theme.colors.bg};
  transition: transform ${p => p.theme.animations.slideTransition};

  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-template-rows: repeat(4, 4rem) minmax(0, 1fr);

  /* stylelint-disable */
  grid-template-areas:
    ${({ hasCaption }) => hasCaption ? `
    "l l l l l l"
    "p p p n n n"
    "a a a a a a"
    "i i c c b b"
    "z z z z z z"
    ` : `
    "l l l l l l"
    "p p p n n n"
    "a a a a a a"
    "i i i b b b"
    "z z z z z z"
    `};
  /* stylelint-enable */

  > * {
    border: 0 !important;
    box-shadow: 0 0 0 1px ${p => p.theme.colors.fg};
  }

  ${PanelButton} {
    height: 4rem;
  }

  ${p => !p.isOpen && css`
    transform: translateY(calc(-100% - 1px));
  `}
`

const Backdrop = styled.button`
  position: fixed;
  z-index: ${p => p.theme.zIndices.drawer - 1};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  appearance: none;
  background: transparent;
  box-shadow: none;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
`

const Media = styled(Container)`
  height: var(--window-height, 100vh);

  ${p => p.theme.mediaQueries.sm} {
    padding-top: 6.2rem;
  }
`

export default StudentProfileMobile
