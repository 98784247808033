import urlJoin from 'url-join'
import { uuidToBase64 } from '~/utils/ids'
import { BASE_URL } from '~/config'

/**
 * Return a link to a student profile page
 */
export function getStudentProfileLink (
  slug: string,
  id: string,
  search = '',
  baseUrl = BASE_URL,
) {
  const path = urlJoin(
    baseUrl,
    '/students/',
    slug,
    uuidToBase64(id),
  )

  return [path, search].filter(p => !!p).join('?').replace(/\?+/, '?')
}
