import * as React from 'react'
import { useQuery } from 'urql'
import { useParams } from 'react-router'
import { Helmet } from 'react-helmet-async'
import { GQLError } from '~/components/GQLError'
import { NotFound } from '~/components/NotFound'
import { GET_STUDENT } from '~/gql/students'
import type { Student } from '~/types'
import { base64ToId } from '~/utils/ids'
import { StudentProfileLayout } from './StudentProfileLayout'
import { StudentProfile } from './StudentProfile'
import { truncate } from '~/utils/strings'

export const StudentProfilePage = () => {
  const params = useParams()
  const id = base64ToId(params.id)
  const [{ data, error, fetching }] = useQuery<{ student: Student }>({
    query: GET_STUDENT, variables: { id: id ?? '' },
  })

  if (fetching) return null
  if (error) return <GQLError error={error} />
  if (!data?.student) return <NotFound />

  const description = truncate(data.student.bio.replace(/[\r\n|\r|\n]+/, ' '), 200)

  return (
    <>
      <Helmet title={data.student.name}>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
      </Helmet>
      <StudentProfileLayout>
        <StudentProfile student={data.student} />
      </StudentProfileLayout>
    </>
  )
}

export default StudentProfilePage
