/**
 * Truncates a string to max chars
 *
 * @param input  The string to truncate
 * @param max  The maximum length of the string
 * @param end  (optional) String to end with (defaults to ellipsis)
 *
 */
export function truncate (input: string, max: number, end = '…') {
  return input.length > max
    ? input.slice(0, max) + end
    : input
}
