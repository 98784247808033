import * as React from 'react'
import { useEffect, useState, useCallback, useRef } from 'react'
import useChange from '@react-hook/change'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useAnimatePresence } from 'use-animate-presence'
import { useEventListener } from '@mfaindex/ui'
import { IndexList } from './IndexList'
import { useIndexContext } from '~/hooks/useIndexContext'
import { useFilteredStudents } from '~/hooks/useFilteredStudents'
import { IndexSplashPage } from './IndexSplashPage'
import { IndexLayout } from './IndexLayout'

const useFadeIn = typeof window === 'undefined'
  ? () => ({
      ref: useRef<any>(),
      isRendered: false,
      togglePresence: () => undefined,
    })
  : () => {
      return useAnimatePresence({
        variants: { opacity: { from: 0, to: 1 } },
        initial: 'hidden',
        duration: 250,
        options: { stiffness: 1, mass: 1, damping: 1 },
      })
    }

export const IndexListPage = () => {
  const index = useIndexContext()
  const students = useFilteredStudents()

  // When there is only a single filter selected, we use it to generate a site title.
  const school = index.filters.school.size === 1
    ? index.schools.find(s => index.filters.school.has(s.slug))
    : undefined

  const year = index.filters.year.size === 1
    ? index.years.find(y => index.filters.year.has(y))
    : undefined

  const keyword = index.filters.keyword.size === 1
    ? index.keywords.find(k => index.filters.keyword.has(k.slug))
    : undefined

  const titleParts = [year, school?.name, keyword?.name].filter(Boolean)
  const title = titleParts.length > 0 ? titleParts.join(', ') : null

  // Splash page state
  const location = useLocation()
  const [splashPageVisible, setSplashPageVisible] = useState(false)

  const showSplashPage = () => {
    if (!window.sessionStorage.getItem('splashShown')) {
      setSplashPageVisible(true)
    }
  }

  const hideSplashPage = () => {
    setSplashPageVisible(false)
  }

  // Show splash on initial mount
  useEffect(() => {
    if (location.pathname === '/' && !location.search) {
      showSplashPage()
    }
  }, [])

  // Hide if location changes
  useChange(location.pathname, hideSplashPage)
  useChange(location.search, hideSplashPage)
  useChange(index.view, hideSplashPage)

  // Handle splash page click
  const onSplashClick = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault()
    e.stopPropagation()
    hideSplashPage()
  }

  useEventListener('scroll', useCallback(() => {
    hideSplashPage()
  }, []))

  // Animate splash page
  const splash = useFadeIn()
  useChange(splashPageVisible, () => { splash.togglePresence() })

  return (
    <>
      {!!title && <Helmet title={title} />}

      {splash.isRendered && (
        <IndexSplashPage
          onClick={onSplashClick}
          onTouchMove={hideSplashPage}
          ref={splash.ref}
        />
      )}

      <IndexLayout>
        <IndexList view={index.view} students={students} />
      </IndexLayout>
    </>
  )
}

export default IndexListPage
