import { Provider, Client as UrqlClient } from 'urql'
import { ThemeProvider } from '@emotion/react'
import { Head } from '~/components/Head'
import { SiteLayout } from '~/components/SiteLayout'
import { GlobalStyle } from '~/styles/global'
import { GlobalCSSVars } from '~/components/GlobalCSSVars'
import { ErrorBoundary } from '~/components/ErrorBoundary'
import { ScrollToTop } from '~/components/ui/ScrollToTop'
import { theme } from '~/styles/theme'
import { routes } from '~/routes'

type Props = {
  urqlClient: UrqlClient,
}

export const App = ({ urqlClient }: Props) => (
  <Provider value={urqlClient}>
    <ThemeProvider theme={theme}>
      <Head />
      <GlobalStyle />
      <GlobalCSSVars />
      <ScrollToTop />
      <SiteLayout>
        <ErrorBoundary>{routes}</ErrorBoundary>
      </SiteLayout>
    </ThemeProvider>
  </Provider>
)

export default App
