import { BrowserRouter } from 'react-router-dom'
import { Client as UrqlClient } from 'urql'
import { HelmetProvider } from 'react-helmet-async'
import { CacheProvider, EmotionCache } from '@emotion/react'
import App from '../App'

export type Props = {
  urqlClient: UrqlClient,
  emotionCache: EmotionCache,
}

export const ClientApp = ({ urqlClient, emotionCache }: Props) => (
  <BrowserRouter>
    <HelmetProvider>
      <CacheProvider value={emotionCache}>
        <App urqlClient={urqlClient} />
      </CacheProvider>
    </HelmetProvider>
  </BrowserRouter>
)

export default ClientApp
