import * as React from 'react'
import styled from '@emotion/styled'
import { Image } from '~/components/ui/Image'
import type { Image as ImageType } from '~/types'

type Props = {
  item: ImageType,
  zoom?: number,
  offset?: [number, number],
  zoomTransition?: number,
}

export const MediaItemImage = ({ item, zoom = 1, offset = [0, 0], zoomTransition = 300 }: Props) => {
  const isZoomed = (zoom ?? 1) > 1
  return (
    <ImageContainer
      style={{
        // stylelint-disable-next-line
        transform: [offset ? `translate3d(${offset[0]}px, ${offset[1]}px, 0)` : '', zoom ? `scale(${zoom})` : ''].join(' '),
        transition: zoom ? `transform ${zoomTransition}ms` : undefined,
        transformOrigin: 'center',
      }}
    >
      <Image
        src={isZoomed ? item.props.sizes['4k'].url : item.props.sizes['480x360'].url}
        srcSet={isZoomed ? undefined : [
          `${item.props.sizes['220x220'].url} 220w`,
          `${item.props.sizes['480x360'].url} 480w`,
          `${item.props.sizes['720x480'].url} 720w`,
          `${item.props.sizes['1280x720'].url} 1280w`,
          `${item.props.sizes['1920x1200'].url} 1920w`,
          `${item.props.sizes['2560x1440'].url} 2560w`,
          `${item.props.sizes['3840x2160'].url} 3840w`,
        ].join(', ')}
        sizes={isZoomed ? undefined : [
          '(max-width: 767px) calc(100vw - 4.6rem)',
          'calc(100vw - 36rem)',
        ].join(', ')}
        style={{
          // stylelint-disable
          width: isZoomed ? `${zoom * 100}%` : undefined,
          height: isZoomed ? `${zoom * 100}%` : undefined,
          transform: isZoomed ? `translate3d(-50%, -50%, 0) scale(${1 / zoom})` : undefined,
          // stylelint-enable
        }}
        draggable={false}
        onDragStart={e => { e.preventDefault() }}
      />
    </ImageContainer>
  )
}

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    display: block;
    user-select: none;
    -webkit-user-drag: none;
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`

export default MediaItemImage
