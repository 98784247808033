import { useLocation, useParams } from 'react-router-dom'
import { useFilteredStudents } from '~/hooks/useFilteredStudents'
import { getStudentProfileLink } from '~/utils/students'
import { base64ToId } from '~/utils/ids'

/**
 * A hook that returns links for the next & previous students for the index.
 */
export const useNextPrevStudents = () => {
  const location = useLocation()
  const params = useParams()

  const students = useFilteredStudents()
  if (students.length < 3) return [null, null]

  const curIndex = students.findIndex(s => s.id === base64ToId(params.id))
  if (curIndex < 0) return [null, null]

  const nextStudent = students[curIndex + 1] ?? students[0]
  const prevStudent = students[curIndex - 1] ?? students.slice(-1)[0]

  const nextLink = getStudentProfileLink(nextStudent.slug, nextStudent.id, location.search)
  const prevLink = getStudentProfileLink(prevStudent.slug, prevStudent.id, location.search)

  return [
    {
      url: prevLink,
      student: prevStudent,
    },
    {
      url: nextLink,
      student: nextStudent,
    },
  ]
}
