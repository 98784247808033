import { Helmet } from 'react-helmet-async'
import { useConfigValues } from '~/hooks/useConfigValues'

export const Head = () => {
  const [{ data }] = useConfigValues()
  const metatags = data?.configValues.metatags
  const siteTitle = metatags?.title ?? ''

  const description = metatags?.description.replace(/[\r\n|\r|\n]+/, ' ') ?? ''

  return (
    <Helmet titleTemplate={`%s | ${siteTitle}`} defaultTitle={siteTitle}>
      <html lang="en" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:title" content={siteTitle} />
      <meta property="og:description" content={description} />
      <link rel="icon" href="data:;base64,iVBORw0KGgo=" />
    </Helmet>
  )
}

export default Head
