import * as React from 'react'
import { useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { Video } from '~/components/ui/Video'
import type { Video as VideoType } from '~/types'

type Props = {
  item: VideoType,
  isActive: boolean,
}

export const MediaItemVideo = ({ item, isActive }: Props) => {
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    if (!isActive) setIsPlaying(false)
  }, [isActive])

  const handlePlayClick = useCallback(() => {
    setIsPlaying(true)
  }, [setIsPlaying])

  return (
    <VideoContainer
      style={{
        maxWidth: `${item.props.width}px`,
      }}
    >
      <Video
        isPlayable={isActive}
        isPlaying={isPlaying}
        onPlayClick={handlePlayClick}
        provider={item.props.provider}
        embed={item.props.embed}
        width={item.props.width}
        height={item.props.height}
        imgSrc={item.props.thumbnails.hq.url}
        imgAlt={item.caption || item.props.title}
      />
    </VideoContainer>
  )
}

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    user-select: none;
    -webkit-user-drag: none;
  }
`

export default MediaItemVideo
