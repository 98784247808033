import { useHttpContext } from '~/components/HttpContext'

export type Props = {
  code: number,
}

export const HttpStatus = ({ code }: Props) => {
  const { update } = useHttpContext()
  update({ status: code })
  return null
}

export default HttpStatus
