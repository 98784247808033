import * as React from 'react'
import styled from '@emotion/styled'
import { Container } from '@mfaindex/ui'
import { ContactForm } from '~/components/ContactForm'
import { IndexDrawerLeft } from './IndexDrawerLeft'
import { IndexPanelRight } from './IndexPanelRight'

export type Props = {
  children: React.ReactNode,
}

export const IndexLayout = ({ children }: Props) => {
  return (
    <Layout px={['3.8rem', '6rem']}>
      <ContactForm />
      <IndexDrawerLeft />
      <IndexPanelRight />
      <Main as="main">
        {children}
      </Main>
    </Layout>
  )
}

export const Layout = styled(Container)`
`

export const Main = styled(Container)`
  min-height: var(--window-height, 100vh);
`

export default IndexLayout
