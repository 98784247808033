import { useEffect } from 'react'
import { CombinedError } from 'urql'
import { ErrorView } from '~/components/ErrorView'
import { DEBUG } from '~/config'

export type Props = {
  error: CombinedError,
}

export const GQLError = ({ error }: Props) => {
  const title = 'Oh no! Something went wrong!'
  const message = DEBUG ? error.message.split(':')[0] || 'Unknown Error' : 'Server Error'
  const debugMessage = DEBUG ? JSON.stringify(error, null, 2) : ''

  useEffect(() => {
    console.log('GraphQL Error', error)
  }, [error])

  return (
    <ErrorView
      title={title}
      debugMessage={debugMessage}
      statusCode={500}
    >
      <p>{message}</p>
    </ErrorView>
  )
}

export default GQLError
