import { base16, base64url as base64 } from 'rfc4648'

/**
 * Formats a uuid hex string with dashes
 */
export function formatUuid (id: string): string {
  const hex = id.replace(/-/g, '')

  if (!/^[0-9a-f]{32}$/i.test(hex)) {
    throw new Error(`Invalid UUID: ${id}`)
  }

  return [
    hex.substr(0, 8),
    hex.substr(8, 4),
    hex.substr(12, 4),
    hex.substr(16, 4),
    hex.substr(20),
  ].join('-').toLowerCase()
}

/**
 * Convert base64 id to normal hex format
 */
export function base64ToId (input: string): string | null {
  try {
    return formatUuid(base16.stringify(base64.parse(input, { loose: true })))
  }
  catch (e) {
    return null
  }
}

/**
 * Convert uuid string into a byte array
 */
export function uuidToBytes (uuid: string): Uint8Array {
  return base16.parse(uuid.replace(/-/g, ''))
}

/**
 * Returns the uuid as a url-safe base64 encoded string
 */
export function uuidToBase64 (uuid: string): string {
  return base64.stringify(uuidToBytes(uuid), { pad: false })
}
