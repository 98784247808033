import * as React from 'react'
import styled from '@emotion/styled'
import { Container } from '@mfaindex/ui'
import { ContactForm } from '~/components/ContactForm'
import { StudentProfileLeftPanel } from './StudentProfileLeftPanel'
import { StudentProfileRightPanel } from './StudentProfileRightPanel'

export type Props = {
  children: React.ReactNode,
}

export const StudentProfileLayout = ({ children }: Props) => {
  return (
    <Layout pl={[0, '6rem']} pr={[0, '6.1rem']}>
      <ContactForm />
      <StudentProfileLeftPanel />
      <StudentProfileRightPanel />
      <Main as="main">
        {children}
      </Main>
    </Layout>
  )
}

export const Layout = styled(Container)`
  overflow: hidden;
`

export const Main = styled(Container)`
  min-height: var(--window-height, 100vh);
`

export default StudentProfileLayout
