import * as React from 'react'
import cx from 'classnames'
import styled from '@emotion/styled'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { useDebounceCallback } from '@react-hook/debounce'
import {
  Stack,
  Drawer,
  DrawerContent,
  DrawerPanel,
  DrawerPanelButton as PanelButton,
  Modal,
  ModalBody,
  ModalContent,
  useModal,
  Portal,
  Button,
  Input,
  FormLabel,
  FormControl,
  Text,
} from '@mfaindex/ui'
import { useIndexContext } from '~/hooks/useIndexContext'

export const IndexPanelRight = () => {
  const index = useIndexContext()
  const location = useLocation()
  const navigate = useNavigate()

  const handleToggleSort = () => {
    const params = new URLSearchParams(location.search)
    if (index.sortBy === null) params.set('sortBy', '1')
    else if (index.sortBy === 1) params.set('sortBy', '-1')
    else params.delete('sortBy')
    const querystring = `?${params.toString()}`
    navigate({
      pathname: location.pathname,
      search: querystring === '?' ? undefined : querystring,
    })
  }

  const selectView = (view: 'list' | 'image' | 'slideshow') => () => index.setView(view)

  // search modal
  const {
    isModalOpen: isSearchOpen,
    openModal: openSearch,
    closeModal: closeSearch,
  } = useModal(false)

  const handleSearchSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const params = new URLSearchParams(location.search)
    const form = e.target as HTMLFormElement
    const data = new FormData(form)
    const searchQuery = (data?.get('search') as string).trim()
    if (searchQuery) params.set('search', searchQuery)
    else params.delete('search')
    const querystring = `?${params.toString()}`
    navigate({
      pathname: location.pathname,
      search: querystring === '?' ? undefined : querystring,
    })
    closeSearch()
  }

  const handleSearchChange = useDebounceCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    index.setSearchQuery(e.target.value || null)
  }, 250)

  const clearSearch = () => {
    const params = new URLSearchParams(location.search)
    params.delete('search')
    const querystring = `?${params.toString()}`
    navigate({
      pathname: location.pathname,
      search: querystring === '?' ? undefined : querystring,
    })
  }

  return (
    <>
      <Drawer orientation="right">
        <DrawerContent>
          <DrawerPanel bg="transparent">
            <PanelButton
              square
              onClick={handleToggleSort}
              className={cx({ random: !index.sortBy })}
            >
              {index.sortBy === null && <>~A</>}
              {index.sortBy === 1 && <>A&ndash;Z</>}
              {index.sortBy === -1 && <>Z&ndash;A</>}
            </PanelButton>
            <PanelButton
              onClick={selectView('image')}
              colorVariant={index.view === 'image' ? 'inverted' : undefined}
              disabled={index.view === 'image'}
            >
              Grid
            </PanelButton>
            <PanelButton
              onClick={selectView('list')}
              colorVariant={index.view === 'list' ? 'inverted' : undefined}
              disabled={index.view === 'list'}
            >
              List
            </PanelButton>
            <PanelButton
              onClick={selectView('slideshow')}
              colorVariant={index.view === 'slideshow' ? 'inverted' : undefined}
              disabled={index.view === 'slideshow'}
            >
              Slideshow
            </PanelButton>
            <SearchButton
              onClick={index.searchQuery ? clearSearch : openSearch}
              colorVariant={index.searchQuery ? 'inverted' : undefined}
            >
              <div>{index.searchQuery ?? 'Search' }</div>
              <div>{index.searchQuery ? 'Clear' : 'Search' }</div>
            </SearchButton>
            <PanelButton
              square
              as={p => (
                <Link {...p} to={{ pathname: 'random', search: location.search }} />
              )}
            >
              <Text as="span" fontSize="2rem">∞</Text>
            </PanelButton>
          </DrawerPanel>
        </DrawerContent>
      </Drawer>

      {isSearchOpen && (
        <Portal>
          <Modal onClose={closeSearch}>
            <ModalContent>
              <ModalBody width="calc(100vw - 4rem)" maxWidth="50rem" py={2}>
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  width="100%"
                  as="form"
                  onSubmit={handleSearchSubmit}
                >
                  <FormControl name="search" width="100%">
                    <FormLabel>Enter a search term:</FormLabel>
                    <Input onChange={handleSearchChange} autoComplete="off" />
                  </FormControl>
                  <Button type="submit" width="20rem">Done</Button>
                </Stack>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Portal>
      )}
    </>
  )
}

const SearchButton = styled(PanelButton)`
  position: relative;

  div:last-of-type {
    display: none;
  }

  @media (pointer: fine) {
    &:not(:hover) {
      div:first-of-type {
        display: block;
      }

      div:last-of-type {
        display: none;
      }
    }

    &:hover {
      div:first-of-type {
        display: none;
      }

      div:last-of-type {
        display: block;
      }
    }
  }
`

export default IndexPanelRight
