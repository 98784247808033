import * as React from 'react'
import { useState, useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { useMediaQuery } from '@mfaindex/ui'
import { StudentProfileDesktop } from './StudentProfileDesktop'
import { StudentProfileMobile } from './StudentProfileMobile'
import { Student } from '~/types'

export type Props = {
  student: Student,
  className?: string,
}

export const StudentProfile = ({ student, className }: Props) => {
  // Figure whether to show mobile or desktop versions
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.mediaQueries.md)
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    setIsLoaded(true)
  }, [])

  // active media item state
  const [activeMediaItem, setActiveMediaItem] = useState(0)
  const handleShowItem = (i: number) => setActiveMediaItem(i)

  // Render mobile or desktop
  const props = {
    student,
    activeMediaItem,
    onShowItem: handleShowItem,
  }

  return isDesktop && isLoaded
    ? <StudentProfileDesktop {...props} isLoaded={isLoaded} />
    : <StudentProfileMobile {...props} isLoaded={isLoaded} />
}

export default StudentProfile
