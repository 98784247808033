/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import * as React from 'react'
import cx from 'classnames'
import styled from '@emotion/styled'
import { useGesture } from 'react-use-gesture'
import { useEventListener, useWheelIndicator, WheelIndicatorEvent } from '@mfaindex/ui'
import { SlideViewItem } from './SlideViewItem'
import { Orientation, Direction, PanGestureState, PinchGestureState } from './types'

export type Props = {
  items: React.ReactNode[],
  activeIndex: number,
  orientation: Orientation,
  dragOffset: number,
  pointerPosition: Direction,
  transition: number,
  width: number,
  height: number,
  onResize: (rect: DOMRect) => void,
  onNextClick: (e: React.MouseEvent) => void,
  onPrevClick: (e: React.MouseEvent) => void,
  onKeyDown: (e: KeyboardEvent) => void,
  onMouseMove: (e: React.MouseEvent) => void,
  onMouseLeave: (e: React.MouseEvent) => void,
  onMouseWheel: (e: WheelIndicatorEvent) => void,
  onDrag: (e: PanGestureState) => void,
  onPinch?: (e: PinchGestureState) => void,
  nextButton?: React.ReactNode,
  prevButton?: React.ReactNode,
  disableInput?: boolean,
  className?: string,
  id?: string,
}

export const SlideView = ({
  items,
  activeIndex,
  orientation,
  dragOffset,
  pointerPosition,
  transition,
  width,
  height,
  onResize,
  onNextClick,
  onPrevClick,
  onKeyDown,
  onMouseMove,
  onMouseLeave,
  onMouseWheel,
  onDrag,
  onPinch = () => undefined,
  nextButton = <span>&gt;</span>,
  prevButton = <span>&lt;</span>,
  disableInput = false,
  className,
  id,
}: Props) => {
  // Calculate 3 items to render (current, next, and previous)
  const currentItems = items
    .map((item, i) => {
      const count = items.length
      let placement: number
      if (activeIndex === i) {
        placement = 0
      }
      else {
        placement = i - activeIndex
        if (placement >= count - 1) {
          placement -= count
        }
        else if (placement <= (-1 * count) + 1) {
          placement += count
        }
      }

      return {
        item,
        index: i,
        placement,
      }
    })
    .filter(item => Math.abs(item.placement) <= 1)

  // Dom ref
  const ref = React.useRef<HTMLDivElement>(null)

  // Attach keyboard listener
  useEventListener('keydown', onKeyDown, {
    event: { capture: true },
  })

  // Attach window resize listener
  const handleResize = React.useCallback(() => {
    if (!ref.current) return
    onResize(ref.current.getBoundingClientRect())
  }, [ref, onResize])

  React.useEffect(handleResize, [])
  useEventListener('resize', handleResize)

  // Wheel indicator
  useWheelIndicator(ref, onMouseWheel, true)

  // Drag touch event
  useGesture({
    onDrag: onDrag as any,
    onPinch: onPinch as any,
  }, {
    domTarget: ref,
    eventOptions: { passive: false },
    drag: { filterTaps: true },
  })

  // Render variables
  const isVertical = orientation === 'vertical'
  const size = isVertical ? height : width
  const transformPx = (size * activeIndex * -1) + dragOffset
  const transform = isVertical
    ? `translate3d(0, ${transformPx}px, 0)`
    : `translate3d(${transformPx}px, 0, 0)`

  const atStart = activeIndex === 0
  const atEnd = activeIndex === items.length - 1

  return (
    <SlideViewWrapper
      ref={ref}
      className={cx(className, `SlideView--${orientation}`, {
        'SlideView--single-item': items.length < 2,
        'SlideView--pointer-prev': pointerPosition === Direction.PREV,
        'SlideView--pointer-next': pointerPosition === Direction.NEXT,
        'SlideView--at-start': atStart,
        'SlideView--at-end': atEnd,
        'SlideView--disable-input': disableInput,
      })}
      role="group"
      aria-roledescription="carousel"
    >
      {!!prevButton && (
        <PrevButton
          onClick={onPrevClick}
          aria-label="Previous item"
          aria-disabled={atStart}
        >
          {prevButton}
        </PrevButton>
      )}

      {!!nextButton && (
        <NextButton
          onClick={onNextClick}
          aria-label="Next item"
          aria-disabled={atEnd}
        >
          {nextButton}
        </NextButton>
      )}

      <Slider
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
        style={{
          height: height || '',
          width: width || '',
          transform,
          transition: transition ? `transform ${transition}ms` : 'none',
        }}
        id={id}
        aria-live="polite"
      >
        {currentItems.map(({ item, placement, index }) => (
          <SlideViewItem
            key={index}
            width={width}
            height={height}
            index={index}
            placement={placement}
            orientation={orientation}
            aria-roledescription="slide"
            aria-label={`${index + 1} of ${items.length}`}
            aria-hidden={placement !== 0}
          >
            {item}
          </SlideViewItem>
        ))}
      </Slider>
    </SlideViewWrapper>
  )
}

const SlideViewWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  touch-action: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`

const Slider = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

const NavButton = styled.button`
  position: absolute;
  z-index: 10;
  left: 0;
  width: 100%;
  cursor: pointer;
  color: black;
  outline: none;
  background: transparent;
  border: 0;
  user-select: none;

  > svg {
    height: 100%;
  }

  .SlideView--single-item & {
    display: none;
  }

  .SlideView--disable-input & {
    cursor: default;
  }

  ${p => p.theme.mediaQueries.xs} {
    padding: 0 2rem 2rem 2rem;
    font-size: 2.8rem;
  }

  ${p => p.theme.mediaQueries.sm} {
    height: 5.5rem;
    justify-content: center;
    font-size: 3.6rem;
  }
`

const NextButton = styled(NavButton)`
  bottom: 0;
  cursor: s-resize;

  .SlideView--pointer-next & {
    color: ${p => p.theme.colors.gray500};
  }

  .SlideView--at-end & {
    cursor: default;
  }
`

const PrevButton = styled(NavButton)`
  top: 0;
  cursor: n-resize;

  .SlideView--pointer-prev & {
    color: ${p => p.theme.colors.gray500};
  }

  .SlideView--at-start & {
    cursor: default;
  }
`

export default SlideView
