import * as React from 'react'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'

export type Props = {
  children?: React.ReactNode,
}

/**
 * A component to automatically scroll to the top of the page when the route
 * location changes.
 */
export const ScrollToTop = ({ children }: Props) => {
  const location = useLocation()

  // track location history
  const visited = useRef(new Set([location.key]))

  useEffect(() => {
    const state = location.state as any
    const shouldUpdate = (
      !state?.modal &&
      !location.hash &&
      !visited.current.has(location.key)
    )
    if (shouldUpdate) window.scrollTo(0, 0)

    visited.current.add(location.key)
  }, [location.pathname])

  return <>{children}</>
}

export default ScrollToTop
