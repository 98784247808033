import * as React from 'react'
import styled from '@emotion/styled'
import { useLocation, Link } from 'react-router-dom'
import {
  Box,
  Container,
  Button,
} from '@mfaindex/ui'
import { Image } from '~/components/ui/Image'
import { SlideView } from '~/components/ui/SlideView'
import { getStudentProfileLink } from '~/utils/students'
import { useIndexContext } from '~/hooks/useIndexContext'
import type { ListStudent, Media } from '~/types'

export type Props = {
  students: ListStudent[],
  className?: string,
}

export const SlideshowIndexList = ({ students, className }: Props) => {
  const location = useLocation()

  const getLink = (student: ListStudent) => (
    getStudentProfileLink(student.slug, student.id, location.search, location.pathname)
  )

  const { slideshowIndex, setSlideshowIndex } = useIndexContext()

  return (
    <SlideshowIndexWrapper className={className}>
      <SlideView
        activeIndex={slideshowIndex}
        onShowItem={setSlideshowIndex}
        nextButton={null}
        prevButton={null}
      >
        {students.map(student => (
          <StudentSlide key={student.id}>
            <SlideshowButton
              as={p => <Link {...p} to={getLink(student)} />}
            >
              {student.name}
            </SlideshowButton>
            {((mediaItem?: Media) => {
              switch (mediaItem?.type) {
                case 'image':
                  return (
                    <Image
                      src={mediaItem.props.sizes['1920x1080'].url}
                      srcSet={[
                        `${mediaItem.props.sizes['220x220'].url} 220w`,
                        `${mediaItem.props.sizes['480x360'].url} 480w`,
                        `${mediaItem.props.sizes['720x480'].url} 720w`,
                        `${mediaItem.props.sizes['1280x720'].url} 1280w`,
                        `${mediaItem.props.sizes['1920x1200'].url} 1920w`,
                        `${mediaItem.props.sizes['2560x1440'].url} 2560w`,
                        `${mediaItem.props.sizes['3840x2160'].url} 2560w`,
                      ].join(', ')}
                      sizes="100vw"
                      draggable={false}
                      onDragStart={e => { e.preventDefault() }}
                    />
                  )
                case 'video':
                  return (
                    <Image
                      src={mediaItem.props.thumbnails.hq.url}
                    />
                  )
                default:
                  return null
              }
            })(student.media[0])}
          </StudentSlide>
        ))}
      </SlideView>
    </SlideshowIndexWrapper>
  )
}

const SlideshowIndexWrapper = styled(Container)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--window-height, 100vh);
  overflow: hidden;
`

const StudentSlide = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const SlideshowButton = styled(Button)`
  width: 24rem;
  max-width: calc(100% - 8rem - 2.6rem);
  background: transparent;

  ${p => p.theme.mediaQueries.sm} {
    width: 30rem;
    max-width: 90%;
  }
`

export default SlideshowIndexList
