import * as React from 'react'
import cx from 'classnames'
import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import {
  Box,
  Container,
  Text,
  GridLayout,
} from '@mfaindex/ui'
import { Link } from '~/components/ui/Link'
import { Image } from '~/components/ui/Image'
import { getStudentProfileLink } from '~/utils/students'
import type { ListStudent, Media } from '~/types'

export type Props = {
  students: ListStudent[],
  className?: string,
}

export const ImageIndexList = React.memo(({ students, className }: Props) => {
  const location = useLocation()
  const getLink = (slug: string, id: string) => (
    getStudentProfileLink(slug, id, location.search, location.pathname)
  )

  const n = students.length

  return (
    <ImageIndexWrapper className={className}>
      <GridLayout
        gridGap="1px"
        gridTemplateColumns={[
          'repeat(1, 1fr)',
          n >= 2 ? 'repeat(2, 1fr)' : '1fr',
          n >= 3 ? 'repeat(3, 1fr)' : `repeat(${n}, 1fr)`,
          n >= 4 ? 'repeat(4, 1fr)' : `repeat(${n}, 1fr)`,
          n >= 4 ? 'repeat(5, 1fr)' : `repeat(${n}, 1fr)`,
          n >= 4 ? 'repeat(5, 1fr)' : `repeat(${n}, 1fr)`,
          n >= 4 ? 'repeat(5, 1fr)' : `repeat(${n}, 1fr)`,
        ]}
        className={cx({
          single: students.length === 1,
          twoUp: students.length === 2,
          threeUp: students.length === 3,
          fourUp: students.length === 4,
        })}
      >
        {students.map(student => (
          <StudentItem key={student.id}>
            <Link to={getLink(student.slug, student.id)}>
              <ImageContainer>
                {((mediaItem?: Media) => {
                  switch (mediaItem?.type) {
                    case 'image':
                      return (
                        <Image
                          src={mediaItem.props.sizes['220x220'].url}
                          srcSet={[
                            `${mediaItem.props.sizes['220x220'].url} 220w`,
                            `${mediaItem.props.sizes['480x360'].url} 480w`,
                          ].join(', ')}
                          sizes={[
                            '(max-width: 360px) 220px',
                            '(max-width: 767px) calc(100vw - 20rem)',
                            '(max-width: 1023px) calc(50vw - 8rem)',
                            '(max-width: 1227px) calc(33.33vw - 10rem)',
                            '220px',
                          ].join(', ')}
                          loading="lazy"
                        />
                      )
                      break
                    case 'video':
                      return (
                        <Image src={mediaItem.props.thumbnails.small.url} loading="lazy" />
                      )
                    default:
                      return <Image src="https://test-media.artcodehost.io/mfaindex/media/placeholder.svg" loading="lazy" />
                  }
                })(student.media[0])}
              </ImageContainer>
              <Text>{student.name}</Text>
            </Link>
          </StudentItem>
        ))}
      </GridLayout>
    </ImageIndexWrapper>
  )
})

const ImageIndexWrapper = styled(Container)`

  .single,
  .twoUp,
  .threeUp {
    height: var(--window-height, 100vh);
    overflow: hidden;
  }

  .single {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .twoUp {
    @media (orientation: portrait) {
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: none !important;
    }
  }

  .threeUp {
    @media (orientation: portrait) {
      grid-template-rows: repeat(3, 1fr);
      grid-template-columns: none !important;
    }
  }

  .fourUp {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);


    ${p => p.theme.mediaQueries.sm} {
      height: var(--window-height, 100vh);
      overflow: hidden;
    }

    ${p => p.theme.mediaQueries.xs} {
      grid-template-rows: repeat(4, 1fr);
      grid-template-columns: none !important;
    }
  }
`

const ImageContainer = styled(Container)``

const StudentItem = styled(Box)`
  position: relative;
  height: 0;
  padding-top: 100%;
  box-shadow: 0 0 0 1px ${p => p.theme.colors.fg};

  > ${Link} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding: 4rem 4rem 0 4rem;
    text-decoration: none;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr) 6rem;
    grid-template-areas:
      "image"
      "name";

    ${ImageContainer} {
      grid-area: image;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    ${Image} {
      display: block;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;

      ${p => p.theme.mediaQueries.lg} {
        width: 220px;
        height: 220px;
      }
    }

    ${Text} {
      grid-area: name;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      overflow: hidden;
    }

    @media (pointer: fine) {
      &:hover {
        text-decoration: none;
        color: ${p => p.theme.colors.bg};
        background-color: ${p => p.theme.colors.fg};
      }
    }
  }

  /* special cases for < 5 list items */
  .single &,
  .twoUp &,
  .threeUp &,
  .fourUp & {
    padding-top: 0;

    > ${Link} {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    ${ImageContainer} {
      position: relative;
      width: 220px;
      height: 220px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 0;
    }

    ${Text} {
      align-items: flex-start;
      padding-top: 2rem;
      flex-grow: 0;
    }
  }

  /* Single item - full screen */
  .single & {
    width: 100%;
    height: var(--window-height, 100vh);
  }

  /* 2 x 1 layout */
  .twoUp & {
    @media (orientation: landscape) {
      height: var(--window-height, 100vh);
    }

    /* 1 x 2 layout */
    @media (orientation: portrait) {
      height: calc(var(--window-height, 100vh) / 2);
    }
  }

  /* 3 x 1 layout */
  .threeUp & {
    @media (orientation: landscape) {
      height: var(--window-height, 100vh);
    }

    /* 1 x 3 layout */
    @media (orientation: portrait) {
      height: calc(var(--window-height, 100vh) / 3);
    }
  }

  /* 4 x 4 layout */
  .fourUp & {
    height: calc(var(--window-height, 100vh) / 2);

    @media (orientation: portrait) {
      ${p => p.theme.mediaQueries.xs} {
        height: calc(var(--window-height, 100vh) / 4);
      }
    }
  }
`

export default ImageIndexList
