import * as React from 'react'
import memoize from 'lodash/memoize'
import throttle from 'lodash/throttle'
import type { WheelIndicatorEvent } from '@mfaindex/ui'
import { SlideView } from './SlideView'
import { Orientation, Direction, PanGestureState, PinchGestureState } from './types'

export type Props = {
  children: React.ReactNode,
  activeIndex: number,
  onShowItem: (i: number) => void,
  orientation: Orientation,
  transition: number,
  nextButton?: React.ReactNode,
  prevButton?: React.ReactNode,
  disableInput?: boolean,
  onDrag?: (e: PanGestureState) => void,
  onPinch?: (e: PinchGestureState) => void,
  onPointerChange?: (direction: 'next' | 'prev' | 'current') => void,
  className?: string,
  id?: string,
}

type State = {
  lastActiveIndex: number,
  pointerPosition: Direction,
  dragOffset: number,
  animating: false | {
    timer: ReturnType<typeof setTimeout>,
    forceComplete: () => void,
  },
  dimensions: DOMRect,
}

export class SlideViewController extends React.PureComponent<Props, State> {
  static defaultProps = {
    activeIndex: 0,
    orientation: 'vertical' as const,
    transition: 300,
  }

  state: State = {
    lastActiveIndex: this.props.activeIndex,
    pointerPosition: Direction.NEXT,
    dragOffset: 0,
    animating: false,
    dimensions: ({
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    } as DOMRect),
  }

  // lifecycle
  static getDerivedStateFromProps (props: Props, state: State) {
    // Set offset to 0 when activeIndex is updated
    if (props.activeIndex !== state.lastActiveIndex) {
      return {
        lastActiveIndex: props.activeIndex,
        dragOffset: 0,
      }
    }
    return null
  }

  componentWillUnmount () {
    // cleanup
    const { animating } = this.state
    if (animating && animating.timer) {
      clearTimeout(animating.timer as any)
    }
  }

  componentDidUpdate (prevProps: Props, prevState: State) {
    const { pointerPosition } = this.state
    if (pointerPosition !== prevState.pointerPosition) {
      if (this.props.onPointerChange) {
        this.props.onPointerChange(
          pointerPosition === Direction.NEXT
            ? 'next'
            : pointerPosition === Direction.PREV
              ? 'prev' : 'current',
        )
      }
    }
  }

  setDimensions = (rect: DOMRect) => {
    this.setState({
      dimensions: rect,
    })
  }

  // Actions
  goTo = (i: number) => {
    this.props.onShowItem(i)
  }

  goFirst = () => {
    this.goTo(0)
  }

  goLast = () => {
    this.goTo(this.count - 1)
  }

  slideNext = () => {
    this.slideTo(Direction.NEXT)
  }

  slidePrev = () => {
    this.slideTo(Direction.PREV)
  }

  slideTo = (direction: Direction) => {
    // if still animating, then end the animation
    if (this.state.animating) {
      this.state.animating.forceComplete()
    }

    const goingForwards = direction > 0
    const goingBackwards = direction < 0
    const atStart = this.activeIndex === 0
    const atEnd = this.activeIndex === this.count - 1

    // handle last/first items
    if ((goingForwards && atEnd) || (goingBackwards && atStart)) {
      this.slideTo(Direction.CURRENT)
      return
    }

    // Calculate new item's offset (will be animated to using css transition)
    const { dimensions } = this.state
    const size = this.isVertical
      ? dimensions.height
      : dimensions.width
    const dragOffset = size * (direction * -1)

    // On completing slide animation
    const onComplete = () => {
      this.setState({ animating: false })
      if (goingForwards) this.goTo(this.nextIndex)
      else if (goingBackwards) this.goTo(this.prevIndex)
    }

    // Run onComplete after the transition duration
    const timer = setTimeout(onComplete, this.props.transition + 1)

    this.setState({
      dragOffset,
      animating: {
        timer,
        forceComplete: () => { clearTimeout(timer); onComplete() },
      },
    })
  }

  // Getters
  getIndex (i: number) {
    return Math.min(this.count - 1, Math.max(0, i))
  }

  getItems = memoize(children => React.Children.toArray(children))

  get items (): Array<React.ReactNode> {
    return this.getItems(this.props.children)
  }

  get count (): number {
    return this.items.length
  }

  get activeIndex (): number {
    return this.props.activeIndex
  }

  get nextIndex (): number {
    return this.getIndex(this.props.activeIndex + 1)
  }

  get prevIndex (): number {
    return this.getIndex(this.props.activeIndex - 1)
  }

  get isVertical () {
    return this.props.orientation === 'vertical'
  }

  getPlacement = (i: number): number => {
    if (this.activeIndex === i) {
      return 0
    }
    let placement = i - this.props.activeIndex
    if (placement >= this.count - 1) {
      placement -= this.count
    }
    else if (placement <= (-1 * this.count) + 1) {
      placement += this.count
    }

    return placement
  }

  // Event handlers
  handleKeyDown = throttle((e: KeyboardEvent) => {
    // skip with modifier keys
    if (e.altKey || e.ctrlKey || e.metaKey) return

    switch (e.key) {
      case 'ArrowDown':
      case 'PageDown':
      case 'ArrowRight':
        e.preventDefault()
        this.slideNext()
        break
      case 'ArrowUp':
      case 'PageUp':
      case 'ArrowLeft':
        e.preventDefault()
        this.slidePrev()
        break
      case 'Home':
        e.preventDefault()
        this.goFirst()
        break
      case 'End':
        e.preventDefault()
        this.goLast()
        break
      default:
        break
    }
  }, 100)

  handlePrevClick = (e: React.MouseEvent) => {
    // Input is disabled so bail out
    if (this.props.disableInput) return
    e.stopPropagation()
    this.slidePrev()
  }

  handleNextClick = (e: React.MouseEvent) => {
    // Input is disabled so bail out
    if (this.props.disableInput) return
    e.stopPropagation()
    this.slideNext()
  }

  handleMouseMove = (e: React.MouseEvent) => {
    const { width, height, top, left } = this.state.dimensions
    const pointer = this.isVertical ? e.clientY : e.clientX
    const position = this.isVertical
      ? pointer >= ((height * 0.44) + top) ? Direction.NEXT : Direction.PREV
      : pointer >= ((width * 0.44) + left) ? Direction.NEXT : Direction.PREV

    if (position !== this.state.pointerPosition) {
      this.setState({ pointerPosition: position })
    }
  }

  handleMouseLeave = (e: React.MouseEvent) => {
    this.setState({ pointerPosition: Direction.CURRENT })
  }

  handleMouseWheel = (e: WheelIndicatorEvent) => {
    // bail when ctrl pressed (could be used for zooming)
    if (e.ctrlKey) return

    // Input is disabled so bail out
    if (this.props.disableInput) return

    if (e.direction === 'down') {
      this.slideNext()
    }
    else {
      this.slidePrev()
    }
  }

  handleDrag = (e: PanGestureState) => {
    if (this.props.onDrag) this.props.onDrag(e)

    // Input is disabled so bail out
    if (this.props.disableInput) return

    // Check if we have a tap event instead of drag
    if (e.tap && e.event.pointerType === 'mouse') {
      // only for left mouse clicks
      if (e.event.button !== 0) return

      if (this.state.pointerPosition === Direction.NEXT) {
        this.slideNext()
      }
      else {
        this.slidePrev()
      }
      return
    }

    // Bail if only a single image
    if (this.count < 2) {
      return
    }

    const dragStartThresh = 20 // minimum drag amount to start
    const { dimensions } = this.state
    const size = this.isVertical ? dimensions.height : dimensions.width
    const navDistanceThresh = size * 0.30 // minimum drag amount to trigger nav
    const navVelocityThresh = 0.30 // minimum velocity to trigger nav

    const dragDelta = e.movement[this.isVertical ? 1 : 0]
    const direction = dragDelta < 0
      ? (this.isVertical ? 'up' : 'left')
      : (this.isVertical ? 'down' : 'right')

    // dragging
    if (!e.last && !e.canceled) {
      const dragActiviated = Math.abs(dragDelta) > dragStartThresh
      if (dragActiviated) {
        // Calculate amount to move the dragged item by
        // Movement is less for first/last elements
        const i = this.activeIndex
        const n = this.count
        const isFirstOrLast = (
          (i === n - 1 && (direction === 'up' || direction === 'left')) ||
          (i === 0 && (direction === 'down' || direction === 'right'))
        )

        const dragOffset = dragDelta * (isFirstOrLast ? 0.08 : 0.6)
        this.setState({ dragOffset })
      }
    }
    // drag ended
    else {
      // Calculate if drag amount or velocity is enough to trigger navigating
      const velocity = e.vxvy[this.isVertical ? 1 : 0]
      const navActivated = (
        Math.abs(dragDelta) > navDistanceThresh ||
        Math.abs(velocity) > navVelocityThresh
      )
      // If navigation should be done
      if (navActivated) {
        if (direction === 'up' || direction === 'left') {
          this.slideNext()
        }
        else {
          this.slidePrev()
        }
      }
      // otherwise, return item back into position
      else if (this.state.dragOffset !== 0) {
        this.slideTo(Direction.CURRENT)
      }
    }
  }

  render () {
    return (
      <SlideView
        className={this.props.className}
        id={this.props.id}
        items={this.items}
        activeIndex={this.activeIndex}
        orientation={this.props.orientation}
        dragOffset={this.state.dragOffset}
        pointerPosition={this.state.pointerPosition}
        transition={this.state.animating ? this.props.transition : 0}
        width={this.state.dimensions.width}
        height={this.state.dimensions.height}
        onResize={this.setDimensions}
        onNextClick={this.handleNextClick}
        onPrevClick={this.handlePrevClick}
        onKeyDown={this.handleKeyDown}
        onMouseMove={this.handleMouseMove}
        onMouseLeave={this.handleMouseLeave}
        onMouseWheel={this.handleMouseWheel}
        onDrag={this.handleDrag}
        onPinch={this.props.onPinch}
        nextButton={this.props.nextButton}
        prevButton={this.props.prevButton}
        disableInput={this.props.disableInput}
      />
    )
  }
}

export default SlideViewController
