import type { FullGestureState } from 'react-use-gesture/dist/types'

export type Orientation = 'vertical' | 'horizontal'

export enum Direction {
  NEXT = 1,
  PREV = -1,
  CURRENT = 0,
}

export interface PanGestureState extends FullGestureState<'drag'> {
  event: PointerEvent,
}

export interface PinchGestureState extends FullGestureState<'pinch'> {
  event: PointerEvent,
}
