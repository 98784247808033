import { useMemo } from 'react'
import { useIndexContext } from '~/hooks/useIndexContext'

/**
 * Filters list of students by filters in index context
 */
export function useFilteredStudents () {
  const { students, filters, searchQuery, sortBy } = useIndexContext()

  const filteredStudents = useMemo(() => {
    return students
      // Filter based on index.filters
      .filter(student => {
        // by school
        if (filters.school.size && !filters.school.has(student.school.slug)) {
          return false
        }
        // by year
        if (filters.year.size && !filters.year.has(student.year)) {
          return false
        }
        // by keyword
        if (filters.keyword.size) {
          const matches = student.keywords.filter(k => filters.keyword.has(k.slug))
          if (matches.length < 1) return false
        }
        return true
      })
      // Search - filter by search phrase
      .filter(student => {
        const query = searchQuery?.trim()?.toLowerCase()
        if (!query) return true

        const words = query.split(/\s+/)

        // name
        for (const word of words) {
          if (student.name.toLowerCase().indexOf(word) >= 0) return true
        }

        // bio
        for (const word of words) {
          if (student.bio.toLowerCase().indexOf(word) >= 0) return true
        }

        // school
        for (const word of words) {
          if (student.school.slug.indexOf(word) >= 0) return true
        }

        // keywords
        for (const keyword of student.keywords) {
          for (const word of words) {
            if (keyword.slug.indexOf(word) >= 0) return true
          }
        }

        return false
      })
  }, [students, filters, searchQuery])

  const sortedStudents = useMemo(() => {
    if (sortBy !== null) {
      // Sort students alphabetically by name (or reverse)
      return filteredStudents.sort((a, b) => {
        const key = a.lastName === b.lastName ? 'firstName' : 'lastName'
        const aVal = a[key].toLowerCase()
        const bVal = b[key].toLowerCase()
        return aVal > bVal ? sortBy : sortBy * -1
      })
    }
    else {
      return filteredStudents
    }
  }, [filteredStudents, sortBy])

  return sortedStudents
}
