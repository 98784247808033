import * as React from 'react'
import styled from '@emotion/styled'

export type Props = {
  children: React.ReactNode,
  width: number,
  height: number,
  index: number,
  placement: number,
  orientation: 'vertical' | 'horizontal',
  className?: string,
} & React.HTMLAttributes<HTMLDivElement>

export const SlideViewItem = ({
  children,
  width,
  height,
  index,
  placement,
  orientation,
  className,
  ...props
}: Props) => {
  const transform = orientation === 'vertical'
    ? `translate3d(0, ${index * height}px, 0)`
    : `translate3d(${index * width}px, 0, 0)`

  return (
    <Wrapper
      className={className}
      style={{
        width: width || '',
        height: height || '',
        zIndex: placement === 0 ? 2 : 1,
        transform,
      }}
      {...props}
    >
      {Math.abs(placement) <= 1 ? children : null}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .SlideView--single-item & {
    cursor: default;
  }

  .SlideView--pointer-prev & {
    /* cursor: url(/assets/icons/arrow_up.svg), n-resize; */
    cursor: n-resize;
  }

  .SlideView--pointer-next & {
    /* cursor: url(/assets/icons/arrow_down.svg), s-resize; */
    cursor: s-resize;
  }

  .SlideView--disable-input & {
    cursor: default;
  }

  .SlideView--at-start.SlideView--pointer-prev & {
    cursor: default;
  }

  .SlideView--at-end.SlideView--pointer-next & {
    cursor: default;
  }
`

export default SlideViewItem
