import * as React from 'react'
import { useRef, useState, useEffect, useCallback } from 'react'
import styled from '@emotion/styled'
import { css, useTheme } from '@emotion/react'
import useResizeObserver from '@react-hook/resize-observer'
import {
  Markup,
  Container,
  Button,
  VisuallyHidden,
  useEventListener,
  useRefElement,
} from '@mfaindex/ui'
import { useViewport } from '~/hooks/ui/useViewport'
import { SlideView, PanGestureState, PinchGestureState } from '~/components/ui/SlideView'
import { MediaItem } from './MediaItem'
import type { Media } from '~/types'

type Props = {
  items: Media[],
  onShowItem: (index: number) => void,
  onZoomed?: (zoom: number) => void,
  activeIndex?: number,
  isLoaded?: boolean,
  className?: string,
  id?: string,
}

const MAX_ZOOM = 6 // maximum x zoom level
const DOUBLE_TAP_T = 300 // double-tap time in ms

export const MediaView = React.memo(
  ({ items, onShowItem, onZoomed, isLoaded, className, id, activeIndex = 0 }: Props) => {
    // Get active item
    const activeItem = items[activeIndex] as Media | undefined

    // Get caption
    const caption = activeItem?.captionMarkup ?? ''

    // Caption state
    const [captionRef, captionEl] = useRefElement<HTMLDivElement>()
    const [isCaptionExpanded, setCaptionExpanded] = useState(false)
    const [isCaptionOverflowing, setCaptionOverflowing] = useState(false)

    // Set caption expanded state
    const toggleCaption = () => setCaptionExpanded(state => isCaptionOverflowing ? !state : state)
    const unexpandCaption = () => setCaptionExpanded(false)
    const expandCaption = () => setCaptionExpanded(true)

    // Unexpand caption when active media item changes
    useEffect(unexpandCaption, [activeIndex])

    // Detect caption overflow state
    const detectOverflow = () => {
      if (isCaptionExpanded) return

      const el = captionEl?.firstChild as HTMLDivElement
      if (!el) return

      const isOverflowing = el.scrollWidth > el.clientWidth || el.scrollHeight > el.clientHeight
      setCaptionOverflowing(isOverflowing)
    }

    // Detect if caption element is overflowing
    useEffect(detectOverflow, [caption, captionEl])

    const [ref, wrapperEl] = useRefElement<HTMLDivElement>()
    useResizeObserver(wrapperEl, detectOverflow)

    // zoom controls
    const [zoom, setZoom] = useState(1)
    const [offset, setOffset] = useState<[number, number]>([0, 0])
    const [isDragging, setIsDragging] = useState(false)
    const [isPinching, setIsPinching] = useState(false)

    const maxZoom = activeItem?.type === 'image' ? MAX_ZOOM : 1

    const resetZoom = () => {
      setOffset([0, 0])
      setIsDragging(false)
      setIsPinching(false)
      setZoom(1)
    }

    const zoomIn = () => {
      setZoom(z => Math.min(maxZoom, z + 0.75))
    }

    const zoomOut = () => {
      setZoom(z => {
        const newZ = Math.max(1, z - 0.75)
        if (newZ <= 1) setOffset([0, 0])
        return newZ
      })
    }

    // Reset zoom when the active item canges
    useEffect(resetZoom, [activeIndex])

    // onZoom callback when zoom changes
    useEffect(() => {
      if (onZoomed) onZoomed(zoom)
    }, [zoom])

    // slide controls
    const slideviewRef = useRef<SlideView>(null)
    const showNext = () => {
      resetZoom()
      slideviewRef.current?.slideNext()
    }
    const showPrev = () => {
      resetZoom()
      slideviewRef.current?.slidePrev()
    }

    // zoom panning
    const dblTapTimeout = useRef<null | ReturnType<typeof setTimeout>>(null)

    const handleTap = (e: PanGestureState) => {
      if (zoom > 1) setTimeout(resetZoom)
    }

    const handleDoubleTap = (e: PanGestureState) => {
      if (zoom > 1) setTimeout(resetZoom)
      else if (e.event.pointerType !== 'mouse') setZoom(2.5)
    }

    const handleDrag = useCallback(
      (e: PanGestureState) => {
        // Bail if pinching
        if (e.pinching) return

        // Handle taps
        if (e.tap) {
          // double tap
          if (dblTapTimeout.current) {
            clearTimeout(dblTapTimeout.current)
            dblTapTimeout.current = null
            handleDoubleTap(e)
          }
          // initial tap (potentially sigle or double tap)
          else {
            dblTapTimeout.current = setTimeout(() => {
              handleTap(e)
              dblTapTimeout.current = null
            }, DOUBLE_TAP_T)
          }
        }
        else {
          // Bail if we are not zoomed
          if (zoom === 1) return

          // Set isDragging state
          setIsDragging(e.dragging)

          // Set the offset
          const deltaX = e.delta[0]
          const deltaY = e.delta[1]
          setOffset(([x0, y0]) => {
            const bounds = {
              left: window.innerWidth * 0.5 * zoom,
              right: window.innerWidth * -0.5 * zoom,
              top: window.innerHeight * 0.5 * zoom,
              bottom: window.innerHeight * -0.5 * zoom,
            }
            const x = x0 + deltaX
            const y = y0 + deltaY
            return [
              Math.max(bounds.right, Math.min(bounds.left, x)),
              Math.max(bounds.bottom, Math.min(bounds.top, y)),
            ]
          })
        }
      },
      [zoom, dblTapTimeout],
    )

    // pinch
    const handlePinch = useCallback(
      (e: PinchGestureState) => {
        e.event.preventDefault()
        const { delta: [d] } = e

        // Set pinch state
        const zm1 = d / 100
        setIsPinching(e.pinching)
        setZoom(zm0 => {
          const zm = Math.max(1, Math.min(maxZoom, zm0 + zm1))
          // recenter on initial zoom
          if (zm === 1) setOffset([0, 0])
          return zm
        })
      },
      [],
    )

    // hover state
    const [pointerPosition, setPointerPosition] = useState<'next' | 'prev' | 'current' | null>(null)

    // keyboard
    useEventListener('keydown', useCallback(
      (e: KeyboardEvent) => {
        // ctrl-0 resets zoom
        if (e.ctrlKey && e.key === '0') resetZoom()

        // escape unexpands caption
        if (e.key === 'Escape') setCaptionExpanded(false)
      },
      [],
    ))

    // Slide transition (different from mobile & desktop)
    const theme = useTheme()
    const viewport = useViewport()
    const isMobile = viewport.width < theme.breakpoints.sm
    const isTablet = !isMobile && viewport.width < theme.breakpoints.md
    const slideTransition = !isLoaded ? 0 : (isMobile ? 200 : (isTablet ? 280 : 350))

    // Zoom transition is off when directly dragging or pinching
    const zoomTransition = isDragging || isPinching ? 0 : slideTransition

    return (
      <Wrapper className={className} ref={ref}>
        <SlideView
          ref={slideviewRef}
          id={id}
          activeIndex={activeIndex}
          onShowItem={onShowItem}
          transition={slideTransition}
          nextButton={null}
          prevButton={null}
          disableInput={zoom > 1 || isPinching || isDragging}
          onDrag={handleDrag}
          onPinch={handlePinch}
          onPointerChange={setPointerPosition}
        >
          {items.map((item, i) => (
            <MediaItem
              key={item.id}
              item={item}
              isActive={i === activeIndex}
              zoom={zoom}
              offset={offset}
              zoomTransition={zoomTransition}
            />
          ))}
        </SlideView>

        <BottomPanel expanded={isCaptionExpanded} overflowing={isCaptionOverflowing}>
          <Control
            onClick={showNext}
            aria-controls={id}
            aria-label="Next item"
            aria-disabled={activeIndex === items.length - 1}
            colorVariant={zoom <= 1 && pointerPosition === 'next' ? 'inverted' : undefined}
          >
            <span>&gt;</span>
          </Control>

          <Control
            onClick={showPrev}
            aria-controls={id}
            aria-label="Previous item"
            aria-disabled={activeIndex === 0}
            colorVariant={zoom <= 1 && pointerPosition === 'prev' ? 'inverted' : undefined}
          >
            <span>&lt;</span>
          </Control>

          <Caption
            ref={captionRef}
            px={1}
            expanded={isCaptionExpanded}
            overflowing={isCaptionOverflowing}
            onPointerDown={toggleCaption}
            onFocus={expandCaption}
            onBlur={unexpandCaption}
          >
            <Markup>{caption}</Markup>
            <VisuallyHidden isHidden={isCaptionExpanded || !isCaptionOverflowing}>
              <Ellipsis aria-label="Expand caption">…</Ellipsis>
            </VisuallyHidden>
          </Caption>

          <Control aria-label="Zoom out" onClick={zoomOut} hidden={activeItem?.type !== 'image'}>&ndash;</Control>
          <Control aria-label="Zoom in" onClick={zoomIn} hidden={activeItem?.type !== 'image'}>+</Control>
        </BottomPanel>

        <Count>{activeIndex + 1} / {items.length}</Count>
      </Wrapper>
    )
  },
)

const Wrapper = styled(Container)`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Control = styled(Button)`
  border: 0;
  padding: 0;
  user-select: none;
  height: 100%;

  > span {
    display: block;
    transform: rotate(90deg);
  }

  &:focus-visible {
    border: solid 1px;
  }

  &&:disabled,
  &&[aria-disabled="true"] {
    cursor: default;
  }

  &&[hidden] {
    display: none;
  }

  ${p => p.theme.mediaQueries.xs} {
    width: 4rem;
  }

  ${p => p.theme.mediaQueries.sm} {
    width: 5.6rem;
  }
`

Control.defaultProps = {
  variant: 'rect',
  color: 'text',
  bg: 'transparent',
}

const BottomPanel = styled(Container)<{ expanded: boolean, overflowing: boolean }>`
  position: absolute;
  z-index: 10;
  bottom: 0;
  left: 0;
  width: 100%;
  display: grid;
  border-top: solid 1px;
  grid-template-columns: repeat(2, min-content) minmax(0, 1fr) repeat(2, min-content);

  && > * ~ * {
    border-left: solid 1px;
  }

  /* expanded caption */
  /* stylelint-disable-next-line */
  ${({ expanded, overflowing, theme }) => (expanded && overflowing) && css`
  && {
  height: auto;
  background-color: ${theme.colors.bg};
  }
  `}

  ${p => p.theme.mediaQueries.xs} {
    height: 4rem;
    /* hidden for now on mobile */
    ${p => p.theme.mixins.a11yHidden}
  }

  ${p => p.theme.mediaQueries.sm} {
    height: 5.6rem;
  }

  /*
  * TODO: fixed position is a hack due to scroll in ios Safari 12.1. It might
  * be better to figure out how to disable that instead of fixed position here.
  */
  ${p => p.theme.mediaQueries.smDown} {
    position: fixed;
  }

  ${p => p.theme.mediaQueries.smOnly} {
    left: 6rem;
    width: calc(100% - 12rem);
  }
`

const Ellipsis = styled.button`
  position: absolute;
  z-index: 1;
  right: 1px;
  width: 1em;
  height: 100%;
  padding: 0 ${p => p.theme.space[1]};
  margin: 0;
  border: 0;
  outline: 0;
  appearance: none;
  background: transparent;
  font-weight: bold;
  cursor: pointer;

  &:focus-visible {
    ${p => p.theme.mixins.focus}
  }
`

const Caption = styled(Container)<{ expanded: boolean, overflowing: boolean }>`
  position: relative;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: default;

  &:focus-within {
    border: solid 1px;
  }

  ${Markup} {
    max-width: 100%;
    white-space: pre-line;
  }

  /* Not expanded */
  ${({ expanded, overflowing }) => !expanded && css`
    ${Markup} {
      overflow: hidden;
      text-overflow: ellipsis;
      height: 1.2em;
    }
  `}

  ${({ expanded, overflowing, theme }) => (!expanded && overflowing) && css`
    && {
      padding-right: calc(${theme.space[2]} * 2);
    }

    @media (pointer: fine) {
      &:hover::after {
        content: 'Continued >';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: ${theme.colors.bg};
        background-color: ${theme.colors.fg};
        cursor: pointer;
      }

      &:hover ${Ellipsis} {
        color: ${theme.colors.bg};
        background-color: ${theme.colors.fg};
      }
    }
  `}

  /* expanded */
  ${({ expanded, overflowing, theme }) => (expanded && overflowing) && css`
    padding: ${theme.space[2]} ${theme.space[4]};
  `}
`

const Count = styled.span`
  display: none;
`

export default MediaView
