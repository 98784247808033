import { Route, Routes } from 'react-router-dom'
import { NotFound } from '~/components/NotFound'
import { Typography } from '~/components/Typography'
import { Redirect } from '~/components/Redirect'
import { Index } from '~/components/index/Index'

export const routes = (
  <Routes>
    <Route path="*" element={<Index />} />
    <Route path="index" element={<Redirect to="/" />} />
    <Route path="index/*" element={<Index />} />
    <Route path="typography" element={<Typography />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default routes
