import * as React from 'react'
import { useState, useEffect, useCallback } from 'react'
import window from 'window-or-global'
import styled from '@emotion/styled'
import {
  PlayCircle,
  PlayCircleOutline,
  EmotionIconBase,
  useEventListener,
  useRefElement,
} from '@mfaindex/ui'
import { Image } from '~/components/ui/Image'
import { VideoEmbed } from '~/components/ui/VideoEmbed'

export type Props = {
  provider: 'vimeo' | 'youtube',
  embed: string,
  width: number,
  height: number,
  imgSrc: string,
  imgSizes?: string,
  imgAlt: string,
  isPlaying?: boolean,
  isPlayable?: boolean,
  onPlayClick?: () => void,
  className?: string,
  style?: React.CSSProperties,
}

const SUPPORTS_POINTER = 'PointerEvent' in window

export const _Video = ({
  provider,
  embed,
  width,
  height,
  imgSrc,
  imgSizes,
  imgAlt,
  isPlayable,
  onPlayClick,
  className,
  style,
  ...props
}: Props) => {
  const [playState, setPlayState] = useState(false)

  const isPlaying = isPlayable && (props.isPlaying ?? playState)

  useEffect(() => {
    if (isPlaying === undefined) setPlayState(false)
  }, [isPlaying])

  const [playButtonRef, playButtonElement] = useRefElement<HTMLButtonElement>()

  const handlePlayClick = useCallback((e: PointerEvent | MouseEvent) => {
    e.stopPropagation()
    e.stopImmediatePropagation()
    if (onPlayClick) onPlayClick()
    else setPlayState(true)
  }, [onPlayClick, setPlayState])

  useEventListener(SUPPORTS_POINTER ? 'pointerdown' : 'click', handlePlayClick, {
    domTarget: playButtonElement,
    event: { passive: false },
  })

  return (
    <div
      className={className}
      style={{
        ...style,
        width: '100%',
        height: 0,
        paddingBottom: `${(height / width) * 100}%`,
      }}
    >
      {isPlayable && isPlaying
        ? (
          <VideoEmbed
            provider={provider}
            embed={embed}
            width={width}
            height={height}
          />
          ) : (
            <>
              <Image
                src={imgSrc}
                sizes={imgSizes}
                alt={imgAlt}
              />
              <PlayButton
                title="Play video"
                ref={playButtonRef}
                aria-hidden={!isPlayable}
                tabIndex={isPlayable ? 0 : -1}
              >
                <PlayIcon />
                <PlayIconOver />
              </PlayButton>
            </>
          )}
    </div>
  )
}

const PlayIcon = styled(PlayCircleOutline)``
const PlayIconOver = styled(PlayCircle)``

const PlayButton = styled.button`
  appearance: none;
  border: 0;
  margin: 0;
  box-shadow: none;
  background: transparent;

  display: block;
  padding: 8rem;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:focus-visible {
    outline: solid 1px;
  }

  ${EmotionIconBase} {
    width: 10rem;
    height: 10rem;
  }

  ${PlayIconOver} {
    display: none;
  }

  @media (pointer: fine) {
    &:hover {
      ${PlayIcon} {
        display: none;
      }

      ${PlayIconOver} {
        display: block;
      }
    }
  }
`

export const Video = styled(_Video)`
  position: relative;
  width: 100%;

  ${Image} {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${VideoEmbed} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
  }
`

export default Video
