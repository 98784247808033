import * as React from 'react'
import styled from '@emotion/styled'
import cx from 'classnames'
import { MediaItemImage } from './MediaItemImage'
import { MediaItemVideo } from './MediaItemVideo'
import type { Media } from '~/types'

export type Props = {
  item: Media,
  isActive: boolean,
  className?: string,
  zoom?: number,
  offset?: [number, number],
  zoomTransition?: number,
}

export const MediaItem = React.memo(
  ({ item, isActive, zoom, offset, zoomTransition, className }: Props) => {
    return (
      <Wrapper className={cx(className, `type-${item.type}`)}>
        <Content>
          {(() => {
            switch (item.type) {
              case 'video':
                return (
                  <MediaItemVideo
                    item={item}
                    isActive={isActive}
                  />
                )
              case 'image':
                return (
                  <MediaItemImage
                    item={item}
                    zoomTransition={zoomTransition}
                    zoom={isActive ? zoom : undefined}
                    offset={offset}
                  />
                )
              default:
                return null
            }
          })()}
        </Content>
      </Wrapper>
    )
  },
)

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: white;

  ${p => p.theme.mediaQueries.xs} {
    padding: 2rem 0 8rem 0;
  }

  ${p => p.theme.mediaQueries.sm} {
    padding: 5rem;
    padding-bottom: calc(5rem + 6.2rem);
  }
`

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default MediaItem
