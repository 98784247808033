import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import type { To } from 'history'
import { useHttpContext } from '~/components/HttpContext'
import type { RedirectStatusCode } from '~/components/HttpContext'

export type Props = {
  to: To,
  code?: RedirectStatusCode,
}

export const Redirect = ({ to, code = 302 }: Props) => {
  // Calculate redirect url
  const redirectLocation = typeof to === 'object'
    ? `${to.pathname ?? ''}?${(to.search ?? '').replace('?', '')}#${(to.hash ?? '').replace('#', '')}`
    : to

  // Server-side redirect
  // Update http context so server can return redirect response
  const { update } = useHttpContext()
  update({
    location: redirectLocation,
    status: code,
  })

  // Handle client side redirect
  const navigate = useNavigate()
  useEffect(() => (
    navigate(to, { replace: true })
  ), [])

  return null
}

export default Redirect
